import axios from "axios";
import { _delayCloseOfOverlay } from "./OfflineSyncModule";
import { getBaseUrl, _axiosPendingReqs, _getConfig, _incRetryCountPendingReqinIDB, _removeKeyFromIDB, _removePendingReqFromIDB, _setPendingReqToIDB } from "../../Helper/commonFunctions_v2";
import { idbPendingReqs } from "../indexedDB";

// add function to delete old images of users

const getPendingQCUpdates = async (passedThis = null) => {
    try {
        let dbValues = await idbPendingReqs.get(`qc`);

        if (!!dbValues && Array.isArray(dbValues) && !!dbValues.length) {
            let { offlineSync } = passedThis.state;
            offlineSync = {
                display: true,
                type: "QC",
                count_name: "Qc",
                count: dbValues.length,
                begin_count: dbValues.length,
                success: false,
                error: false,
                begin_unmount: false,
                qcs: dbValues,
            };
            passedThis.setState({ offlineSync }, async () => {
                let pass_count = 0,
                    fail_count = 0;

                for (let i = 0; i < dbValues.length; i++) {
                    dbValues[i].status = `PROCESSING`;
                    offlineSync = {
                        ...offlineSync,
                        qcs: dbValues,
                    };
                    passedThis.setState({ offlineSync });

                    try {
                        await updateQC(dbValues[i], passedThis, offlineSync, i);
                        pass_count++;
                    } catch (error) {
                        console.error(error);
                        fail_count++;
                    }
                }

                offlineSync = {
                    ...offlineSync,
                    display: true,
                    count: dbValues.length,
                    qcs: dbValues,
                    pass_count,
                    fail_count,
                    completed: true,
                };

                passedThis.setState({ offlineSync }, () => {
                    _delayCloseOfOverlay({ passedThis, timeout: 5000 });
                });
            });
        }
    } catch (error) {
        console.error(error);
        _delayCloseOfOverlay({ passedThis, timeout: 5000 });
    }
};

const __getPendingImagesFromPayload = (payload) => {
    // fabric_rolls.point_defects_list.defect_doc_list
    let pending_images_array = [];
    for (let i = 0; i < payload.fabric_rolls.length; i++) {
        for (let j = 0; j < payload.fabric_rolls[i].point_defects_list.length; j++) {
            for (let k = 0; k < payload.fabric_rolls[i].point_defects_list[j].defect_doc_list.length; k++) {
                if (!payload.fabric_rolls[i].point_defects_list[j].defect_doc_list[k]?.url && payload.fabric_rolls[i].point_defects_list[j].defect_doc_list[k].alias) {
                    pending_images_array.push(payload.fabric_rolls[i].point_defects_list[j].defect_doc_list[k].alias);
                }
            }
        }
    }
    return {
        pending_images_array,
        pending_images_count: pending_images_array.length,
    };
};

const _setImageURLonUUID = async (uuid, imageDbReq) =>
    new Promise(async (resolve, reject) => {
        try {
            console.log("Sending request to upload image");
            let data = await _axiosPendingReqs(imageDbReq);
            let qc_id = imageDbReq.uniqueIDValue,
                url = data.url;
            console.log("Image is uploaded", imageDbReq);
            console.log("Assigning image on QC", uuid, qc_id, url);

            let response = await axios.put(
                `${getBaseUrl("dev")}/api/v1/path/qc/updateQcPointDefectUrlByAlias/${qc_id}`,
                {
                    docs: [
                        {
                            url,
                            alias: uuid,
                        },
                    ],
                },
                _getConfig(),
            );
            console.log("removing image from IDB", uuid);

            await _removeKeyFromIDB(uuid);

            resolve("uploaded");
        } catch (error) {
            console.error(error);
            reject("failed");
        }
    });

const _delayAdder = async (time = 5000) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve("done");
        }, time);
    });

const updateQC = async (dbValues, passedThis, offlineSync, idx) => {
    return new Promise(async (resolve, reject) => {
        let qcs = offlineSync.qcs,
            delay_qc_upload = false,
            failed_images_array = [];

        try {
            if (dbValues.retryCount >= dbValues.maxRetryCount) {
                qcs[idx].status = `RETRY_COUNT_REACHED`;

                console.log("retry count reached for qc");
                await _removePendingReqFromIDB(dbValues.type.page, dbValues.uniqueID, dbValues.uniqueIDValue);

                let pending_images_array = dbValues.pending_images_array,
                    pending_images_count = dbValues.pending_images_count;

                for (let i = 0; i < pending_images_array.length; i++) {
                    console.log("finding image in IDB >>> ", pending_images_array[i]);
                    let imageDbReq = await idbPendingReqs.get(pending_images_array[i]);
                    if (imageDbReq) {
                        console.log("remove images associated to qc from IDB");
                        await _removeKeyFromIDB(pending_images_array[i]);
                    }
                }

                offlineSync = {
                    ...offlineSync,
                    qcs,
                };
                passedThis.setState({ offlineSync });

                return;
            }

            if (!dbValues.hasOwnProperty("has_partially_failed") || dbValues.has_partially_failed === false) {
                console.log("Hit QC initial request with data");
                await _axiosPendingReqs(dbValues);
            }

            let pending_images_array = dbValues.pending_images_array,
                pending_images_count = dbValues.pending_images_count;

            if (pending_images_array && Array.isArray(pending_images_array) && pending_images_array.length > 0) {
                qcs[idx].completed_images = 0;
                qcs[idx].failed_images = 0;

                for (let i = 0; i < pending_images_array.length; i++) {
                    try {
                        console.log("finding image in IDB >>> ", pending_images_array[i]);
                        let imageDbReq = await idbPendingReqs.get(pending_images_array[i]);

                        if (imageDbReq) {
                            await _setImageURLonUUID(pending_images_array[i], imageDbReq);

                            qcs[idx].completed_images += 1;
                            offlineSync = {
                                ...offlineSync,
                                qcs,
                            };
                            passedThis.setState({ offlineSync });
                        } else {
                            qcs[idx].failed_images += 1;
                            offlineSync = {
                                ...offlineSync,
                                qcs,
                            };
                            passedThis.setState({ offlineSync });
                        }
                    } catch (error) {
                        qcs[idx].failed_images += 1;
                        offlineSync = {
                            ...offlineSync,
                            qcs,
                        };
                        passedThis.setState({ offlineSync });

                        let imageDbReq = await idbPendingReqs.get(pending_images_array[i]);

                        if (imageDbReq) {
                            if (imageDbReq.retryCount >= imageDbReq.maxRetryCount) {
                                await _removeKeyFromIDB(pending_images_array[i]);
                            } else {
                                delay_qc_upload = true;
                                failed_images_array.push(pending_images_array[i]);
                            }

                            imageDbReq.retryCount += 1;

                            await idbPendingReqs.set(pending_images_array[i], imageDbReq);
                        }
                    }
                }
            }

            if (!delay_qc_upload) {
                // final submit call for fabric qc report
                if (dbValues.fabric_report) {
                    await axios.put(`${getBaseUrl("dev")}/api/v1/path/qc/${dbValues.uniqueIDValue}/submitFabricInspection`, {}, _getConfig());
                }

                console.log("Remove the qc from IDB", dbValues);
                await _removePendingReqFromIDB(dbValues.type.page, dbValues.uniqueID, dbValues.uniqueIDValue);

                qcs[idx].status = `COMPLETED`;
                offlineSync = {
                    ...offlineSync,
                    count: offlineSync.count - 1,
                    qcs,
                };
                passedThis.setState({ offlineSync });
            } else {
                qcs[idx].status = `IMAGES_PENDING`;
                offlineSync = {
                    ...offlineSync,
                    qcs,
                };
                passedThis.setState({ offlineSync });

                _setPendingReqToIDB({
                    ...dbValues,
                    pending_images_array: failed_images_array,
                    pending_images_count: failed_images_array.length,
                    completed_images: 0,
                    failed_images: 0,
                    status: null,
                    has_partially_failed: true,
                });

                reject(dbValues);
            }
            resolve(dbValues);
        } catch (error) {
            console.error(error);
            console.log("Request failed increase retry count");

            qcs[idx].status = "FAILED";
            offlineSync = {
                ...offlineSync,
                count: offlineSync.count - 1,
                qcs,
            };
            passedThis.setState({ offlineSync });

            await _incRetryCountPendingReqinIDB(dbValues.type.page, dbValues.uniqueID, dbValues.uniqueIDValue);

            reject(dbValues);
        }
    });
};

export { getPendingQCUpdates, __getPendingImagesFromPayload };
