// console.log("reducer.js");
// import * as actionTypes from './actions';
// import a new file for method handlers

const initialState = {
    video_url: "",
    products: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            {
                return {
                    ...state,
                };
            }
            return state;
    }
};
// console.log("reducerexport",reducer);

export default reducer;
