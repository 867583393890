import { _existAndGetLsValue, _isJsonString, _setLsValues, _nestedJSONStringToJSON, _matchUniqueIDs } from "../commonFunctions_v2";
import { idbPendingReqs } from "../../util/indexedDB";

/*

const eventHandler = event => {
  // event is a MessageEvent object
  if (_isJsonString(event.data) && JSON.parse(event.data)) {
    let message = JSON.parse(event.data); // latest message received to client
    if(message.action === "CLONE_REQ_TO_LOCAL") { // check if it is a pending offline request
      let key = `PENDING_${message.type.page}`,
      lsValues = _existAndGetLsValue(key);
      lsValues = _nestedJSONStringToJSON(lsValues); // recursive compute JSON from JSON string
      let obj_to_set = {[key]: []};
      if(lsValues.hasOwnProperty(key) && Array.isArray(lsValues[key]) && !!lsValues[key].length) {
        for(let i = 0; i < lsValues[key].length; i++) {
          let uniqueIDs = lsValues[key][i].uniqueID,
          jsonVal = lsValues[key][i].jsonVal;
          // if localstorage pending reqs has same unique id request
          if(!matchUniqueIDs(jsonVal, message.jsonVal, uniqueIDs)) {
            obj_to_set[key].push(lsValues[key][i]);
          }
        }
      }
      // else push to localstorage
      obj_to_set[key].push(message);
      let keys = Object.keys(obj_to_set);
      // Stringify JSON to save to LS
      for(let i = 0; i < keys.length; i++) {
        obj_to_set[keys[i]] = JSON.stringify(obj_to_set[keys[i]])
      }
      // set to LS
      _setLsValues(obj_to_set);
    }
  }
}

*/

const eventHandler = async (event) => {
    // event is a MessageEvent object
    // let saveRequest = {
    // 	action: "CLONE_REQ_TO_LOCAL",
    // 	type: {page: "test", payload: "json", uniqueID: "test"},
    // 	uniqueID: "test",
    // 	method: "GET",
    // 	url: "dev.geniemode.com/test?url=123",
    // 	jsonVal: {
    //     testParam: "345"
    //   },
    // };
    // if (_isJsonString(event.data) && JSON.parse(event.data)) {
    let message = JSON.parse(event?.data); // latest message received to client
    // let message = saveRequest;
    if (message.action === "CLONE_REQ_TO_LOCAL") {
        // check if it is a pending offline request
        let key = `PENDING_${message.type.page}`,
            dbValues = await idbPendingReqs.get(message.type.page);
        // dbValues = _nestedJSONStringToJSON(dbValues); // recursive compute JSON from JSON string
        let obj_to_set = { [key]: [] };
        if (dbValues.hasOwnProperty(key) && Array.isArray(dbValues[key]) && !!dbValues[key].length) {
            for (let i = 0; i < dbValues[key].length; i++) {
                let uniqueIDs = dbValues[key][i].uniqueID,
                    jsonVal = dbValues[key][i].jsonVal;
                // if localstorage pending reqs has same unique id request
                if (!_matchUniqueIDs(jsonVal, message.jsonVal, uniqueIDs)) {
                    obj_to_set[key].push(dbValues[key][i]);
                }
            }
        }
        // else push to localstorage
        obj_to_set[key].push(message);
        // let keys = Object.keys(obj_to_set);
        // Stringify JSON to save to DB
        // for(let i = 0; i < keys.length; i++) {
        //   obj_to_set[keys[i]] = JSON.stringify(obj_to_set[keys[i]])
        // }
        // set to DB
        await idbPendingReqs.set(key, obj_to_set[key]);
    }
    // }
};

export default eventHandler;
