import React, { Suspense } from "react";
import axios from "axios";
import { Provider } from "react-redux";
import reducer from "./store/reducer";
import { createStore } from "redux";
import Loader from "./components/Loader/Loader";
import { axiosIntercept, getUserData, _getCookieValue, _getOrSetIpLocation, _sanitizeWEevent, _setCookieValue, _universal_logger_v2, _pageEnterTime, _pageExitTime, parseJwt, _generateUUID } from "./Helper/commonFunctions_v2";
import { _mathTOFixedNoRounding, _removeURLParam } from "./util/commonFunctions";
import { getPendingQCUpdates } from "./util/OfflinePendingReqs/OfflinePendingReqs";
import "./App.css";

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()),
    Routes = React.lazy(() => import("./Layout/Routes"));

export default class App extends React.Component {
    state = {
        loginCheck: false,
        loggedIn: false,
        key: false,
        postMessage: "test",
        offlineSync: {
            display: false,
            type: "QC",
            count_name: "Qc",
            count: 0,
            success: false,
            error: false,
            begin_unmount: false,
        },
    };

    popStateListener = (event) => {
        window.isPopState = { value: true, key: window?.history?.state?.key };
    };

    checkSetHistoryState = () => {
        window.history.replaceState(
            {
                key: "start1",
                state: "firstCheck",
            },
            !1,
            window.location.href,
        );
        this.setState({ key: true });
    };

    loginOnToken = () => {
        _pageEnterTime({ passedThis: this }, "Portal View Page");
        let urlParams = new URLSearchParams(this.props.location && this.props.location.search);
        if (urlParams.has("token") && !getUserData()?.userId) {
            this._log_login_on_token(urlParams.get("token"));
            window.localStorage.setItem("token", urlParams.get("token"));
            let newUrl = _removeURLParam("token", window.location.href);
            window.history.replaceState({}, null, newUrl);
        }
    };

    _log_login_on_token = (token) => {
        _universal_logger_v2({
            eventName: "Token_Login",
            businessDataObj: {
                token,
                is_token_login: true,
                ...parseJwt(token),
            },
            entityTypeObj: {
                page: "App",
                type: "token_login",
                token,
                ...parseJwt(token),
            },
            triggerInfoObj: {
                Class: "App",
                function: "_log_login_on_token",
            },
            passedThis: this,
        });
    };

    _initial_func = () => {
        if (!!window) {
            if (window?.localStorage?.getItem("tnaAudit")) {
                let date = new Date(),
                    time = date.getTime(),
                    lastViewPopTime = window?.localStorage?.getItem("tnaAudit");
                let difference_time = lastViewPopTime ? time - lastViewPopTime : 0;
                if (difference_time > 18000000) {
                    localStorage.removeItem("tnaAudit");
                }
            }
            if (window?.localStorage?.getItem("showFactoryDocOverlay")) {
                let date = new Date(),
                    time = date.getTime(),
                    lastViewPopTime = window?.localStorage?.getItem("showFactoryDocOverlay");
                let difference_time = lastViewPopTime ? time - lastViewPopTime : 0;
                if (difference_time > 18000000) {
                    localStorage.removeItem("showFactoryDocOverlay");
                }
            }
            if (_getCookieValue("ipLocation")) _setCookieValue("ipLocation", "", -1);
            window.searchKeys = {};
            window.onpopstate = this.popStateListener;
            !window?.history?.state?.key && this.checkSetHistoryState();
            window?.history?.state?.key && this.setState({ key: true });
            axiosIntercept(axios);
            window.webengage && window.webengage.track && window.webengage.track("portalView", { url: window.location.href, react: true });
            document?.referrer?.includes("android-app://") && localStorage.setItem("inAnroidApp", true);
            // let Eventobject = {hello: "", world: null, hritik: undefined, prateek: {first: null, second: undefined, third: "", fourth: {1: "", 2: null, 3: undefined, 4: [1,2,3], 5: [] , 6: "yo", 7: "  ", 8: 123}}};
            // console.log("_sanitizeWEevent(obj)", _sanitizeWEevent(Eventobject));
            // if(navigator.onLine) setTimeout(() => getPendingQCUpdates(this), 3000); // Delaying for dramatic effect and leaving scope to make it faster later :P
            window.addEventListener("online", (e) => {
                console.log("online");
                getPendingQCUpdates(this);
            });
            window.time_spent = {
                page_timer_map: {},
                last_page: "",
                inactivity_time: 0,
                tab_start_time: 0,
                tab_end_time: 0,
                tab_id: _generateUUID(),
                tab_focus_count: 0,
                tab_time_spent: 0,
            };
            window.addEventListener("blur", (e) => {
                // console.log("tab blur at -> ", new Date().toString());
                _universal_logger_v2({ eventName: "tab_end" });
            });
            window.addEventListener("focus", (e) => {
                // console.log("tab focus at -> ", new Date().toString());
                _universal_logger_v2({ eventName: "tab_start" });
            });
        }
    };

    componentDidMount() {
        this.loginOnToken();
        _mathTOFixedNoRounding();
        _getOrSetIpLocation();
        this._initial_func();
    }

    componentWillUnmount() {
        _pageExitTime({ passedThis: this }, "Portal View Page");
    }

    render() {
        let { key, postMessage, offlineSync } = this.state;
        return (
            <React.Fragment>
                <Provider store={store}>
                    <Suspense fallback={<Loader />}>{key && <Routes offlineSync={offlineSync} />}</Suspense>
                </Provider>
            </React.Fragment>
        );
    }
}
