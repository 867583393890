import "react-app-polyfill/stable";
import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import App from "./App";
import eventHandler from "./Helper/offlineEvents/eventsHandler";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

// Sentry Config
if (!!window && !!window.location && !!window.location.href && !(window.location.href.includes("localhost") || window.location.href.includes("devportal") || window.location.href.includes("qaportal") || window.location.href.includes("192.168."))) {
    Sentry.init({
        dsn: process.env.REACT_APP_PROD_FE_PORTAL_SENTRY,
        integrations: [new Integrations.BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

document.addEventListener("DOMContentLoaded", (e) => {
    ReactDOM.render(
        <BrowserRouter>
            <Route path="/" component={App} />
        </BrowserRouter>,
        document.getElementById("main"),
    );
});

window.addEventListener("load", () => {
    // * Service Worker Registration *
    let service_worker_url = "/service_worker.js";
    if (service_worker_url) {
        navigator &&
            navigator.serviceWorker &&
            navigator.serviceWorker.register &&
            typeof navigator.serviceWorker.register === "function" &&
            navigator.serviceWorker
                .register(service_worker_url)
                .then((registration) => {
                    console.log("geniemode service worker", registration);
                })
                .catch((error) => {
                    console.log(error);
                });
        navigator?.serviceWorker &&
            navigator.serviceWorker.addEventListener("message", (e) => {
                eventHandler(e);
            });
    }

    // * Fetch .env API keys *
    let gMeasurementID = process.env.REACT_APP_PROD_FE_PORTAL_GTM,
        clarityID = process.env.REACT_APP_PROD_FE_PORTAL_CLARITY;

    // * WebEngage Config *
    var webengage;
    (function (w, e, b, n, g) {
        function o(e, t) {
            e[t[t.length - 1]] = function () {
                r.__queue.push([t.join("."), arguments]);
            };
        }
        var i,
            s,
            r = w[b],
            z = " ",
            l = "init options track screen onReady".split(z),
            a = "feedback survey notification".split(z),
            c = "options render clear abort".split(z),
            p = "Open Close Submit Complete View Click".split(z),
            u = "identify login logout setAttribute".split(z);
        if (!r || !r.__v) {
            for (w[b] = r = { __queue: [], __v: "6.0", user: {} }, i = 0; i < l.length; i++) o(r, [l[i]]);
            for (i = 0; i < a.length; i++) {
                for (r[a[i]] = {}, s = 0; s < c.length; s++) {
                    o(r[a[i]], [a[i], c[s]]);
                }
                for (s = 0; s < p.length; s++) {
                    o(r[a[i]], [a[i], "on" + p[s]]);
                }
            }
            for (i = 0; i < u.length; i++) {
                o(r.user, ["user", u[i]]);
            }
            setTimeout(function () {
                var f = e.createElement("script"),
                    d = e.getElementById("_webengage_script_tag");
                f.type = "text/javascript";
                f.async = !0;
                f.src = ("https:" == e.location.protocol ? "https://ssl.widgets.webengage.com" : "http://cdn.widgets.webengage.com") + "/js/webengage-min-v-6.0.js";
                d.parentNode.insertBefore(f, d);
            });
        }
    })(window, document, "webengage");

    // * Dev and Prod Checks *
    if (window.location.href.includes("devportal.geniemode.com")) {
        window.webengage.init(process.env.REACT_APP_DEV_FE_PORTAL_WEBENGAGE);
        gMeasurementID = process.env.REACT_APP_DEV_FE_PORTAL_GTM;
        clarityID = process.env.REACT_APP_DEV_FE_PORTAL_CLARITY;
    } else if (window.location.href.includes("qaportal.geniemode.com")) {
        window.webengage.init(process.env.REACT_APP_QA_FE_PORTAL_WEBENGAGE);
        gMeasurementID = process.env.REACT_APP_DEV_FE_PORTAL_GTM;
        clarityID = process.env.REACT_APP_DEV_FE_PORTAL_CLARITY;
    } else if (window.location.href.includes("portal.geniemode.com")) {
        window.webengage.init(process.env.REACT_APP_PROD_FE_PORTAL_WEBENGAGE);
    }

    // * Microsoft Clarity *
    if (!(window.location.href.includes("devportal") || window.location.href.includes("localhost") || window.location.href.includes("192.168."))) {
        (function (c, l, a, r, i, t, y) {
            c[a] =
                c[a] ||
                function () {
                    (c[a].q = c[a].q || []).push(arguments);
                };
            t = l.createElement(r);
            t.async = 1;
            t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0];
            y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", clarityID);
    }

    // * Global site tag (gtag.js) - Google Analytics *
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    (function () {
        var wzrk = document.createElement("script");
        wzrk.type = "text/javascript";
        wzrk.async = true;
        wzrk.src = "https://www.googletagmanager.com/gtag/js?id=" + gMeasurementID;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(wzrk, s);
    })();
    gtag("config", gMeasurementID);

    if (window.location.href.includes("portal") && !window.location.href.includes("dev") && !window.location.href.includes("qa")) {
        // * Firebase Initialization *
        // Your web app's Firebase configuration
        // For Firebase JS SDK v7.20.0 and later, measurementId is optional
        const firebaseConfig = {
            apiKey: process.env.REACT_APP_PROD_FE_PORTAL_FIREBASE,
            authDomain: "portal-geniemode.firebaseapp.com",
            projectId: "portal-geniemode",
            storageBucket: "portal-geniemode.appspot.com",
            messagingSenderId: process.env.REACT_APP_PROD_FE_PORTAL_FIREBASE_MESSAGE_SENDER_ID,
            appId: process.env.REACT_APP_PROD_FE_PORTAL_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_PROD_FE_PORTAL_GTAG_ID,
        };
        console.log("check here");

        // Initialize Firebase
        try {
            const app = initializeApp(firebaseConfig);
            const analytics = getAnalytics(app);
            const perf = getPerformance(app);
            window.firebaseAnalytics = analytics;
            if (window?.logEventAfterInitialization) {
                !!analytics && logEvent(analytics, window?.logEventAfterInitialization?.event_name, window?.logEventAfterInitialization?.eventDataToBeLogged);
                !!analytics && !!setUserProperties && setUserProperties(analytics, { ...window?.logEventAfterInitialization?.userProperties });
                window.logEventAfterInitialization = null;
            }
        } catch (error) {
            console.log("Firebase error", error);
        }
    }
});

window.addEventListener("beforeinstallprompt", function (event) {
    event.preventDefault();
});
