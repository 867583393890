import React from "react";
// svgs
import SpinLoader from "../../svgs/SpinLoader";
import { CloudBackup, CloudError, CloudSuccess, CloudSync } from "../../svgs/Svg";
// css
import "../../components/Popup/OfflineDataSavePopup.css";
import { _isiPad } from "../commonFunctions";

let isMobile = typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;

function OfflineSyncModule({ type, count, begin_unmount, pass_count, fail_count, completed }) {
    let success = !!(completed && fail_count === 0),
        error = !!(completed && fail_count >= 1),
        count_name = "QC";
    isMobile = isMobile && !_isiPad();
    return (
        <div className={`br8 h48 ta02 mr10 cuP wp100 ${!begin_unmount ? "fadeIn" : "fadeOut"} ${isMobile ? "bgF" : `gm-grad${success ? "-success" : error ? "-error" : ""}`}`}>
            <div className="h48 oH pR">
                {isMobile ? (
                    <>
                        <div className={`bs ta02 wp100 ${success ? "cGr" : error ? "cP" : "cGmD"}`}>
                            {!success && !error && (
                                <div className="dF aiC h48">
                                    <div className="w16 h16 dF jcC aiC fadeInOut">
                                        <CloudBackup color={`#905739`} />
                                    </div>
                                </div>
                            )}

                            {success && !error && (
                                <div className="dF aiC h48">
                                    <div className="aiC dF h32 jcC mr12 w32">
                                        <CloudSuccess color={`#00af52`} />
                                    </div>
                                </div>
                            )}

                            {!success && error && (
                                <div className="dF aiC h48">
                                    <div className="aiC dF h32 jcC mr12 w32">
                                        <CloudError color={`#F33C05`} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="h64 o3 pA spinLoader-reverse w64" style={{ top: "-5px", left: "-15px" }}>
                            <SpinLoader color={success ? "#00af52" : error ? "#F33C05" : "#C38464"} />
                        </div>
                        <div className={`bs ta02 wp100 ${success ? "cGr" : error ? "cP" : "cGmD"}`}>
                            {!success && !error && (
                                <div className="dF aiC h48">
                                    <div className="fw500 p1020 pr6">Sync Inprogress</div>
                                    <span className="dIb pr20 vM">
                                        <div className="w16 h16 dF jcC aiC fadeInOut">
                                            <CloudBackup color={`#905739`} />
                                        </div>
                                    </span>
                                    {count && !isNaN(count) && count > 0 ? (
                                        <span className="fs28 fwB ml8 mr8 vM">
                                            {count ? count : "0"} <span className="fs10 fw400">{count > 1 ? `${count_name}s` : count_name} pending</span>
                                        </span>
                                    ) : null}
                                </div>
                            )}

                            {success && !error && (
                                <div className="dF aiC h48">
                                    <div className="fw500 p1020 pr6">Sync completed</div>
                                    <div className="">
                                        <span className="dIb pr20 vM">
                                            <div className="aiC dF h32 jcC mr12 w32">
                                                <CloudSuccess color={`#00af52`} />
                                            </div>
                                        </span>
                                        {pass_count && !isNaN(pass_count) && pass_count > 0 ? (
                                            <span className="fs28 fwB ml8 mr8 vM">
                                                {pass_count ? pass_count : "0"} <span className="fs10 fw400">{pass_count > 1 ? `${count_name}s` : count_name} success</span>
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            )}

                            {!success && error && (
                                <div className="dF aiC h48">
                                    <div className="fw500 p1020 pr6">Sync status</div>
                                    <div className="">
                                        <span className="dIb pr20 vM">
                                            <div className="aiC dF h32 jcC mr12 w32">
                                                <CloudError color={`#F33C05`} />
                                            </div>
                                        </span>
                                        {pass_count && !isNaN(pass_count) && pass_count > 0 ? (
                                            <span className="fs28 fwB ml8 mr8 vM">
                                                {pass_count ? pass_count : "0"} <span className="fs10 fw400">{pass_count > 1 ? `${count_name}s` : count_name} success</span>
                                            </span>
                                        ) : null}
                                        {fail_count && !isNaN(fail_count) && fail_count > 0 ? (
                                            <span className="fs28 fwB ml8 mr8 vM">
                                                {fail_count ? fail_count : "0"} <span className="fs10 fw400">{fail_count > 1 ? `${count_name}s` : count_name} failed</span>
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

/**
 * This function will fadeOut the offline popup and then unmount it after animation completion
 * @param {object} {passedThis: this of the parent function}
 */
function _delayCloseOfOverlay({ passedThis = null, timeout = 3000 }) {
    if (!!passedThis) {
        // offlineSync
        setTimeout(() => {
            passedThis.setState(
                (prevState) => ({
                    offlineSync: {
                        ...prevState.offlineSync,
                        begin_unmount: true,
                    },
                }),
                () => {
                    setTimeout(() => {
                        passedThis.setState((prevState) => ({
                            offlineSync: {
                                ...prevState.offlineSync,
                                display: false,
                            },
                        }));
                    }, 4000); // animation delay
                },
            );
        }, timeout);
    }
}

export { OfflineSyncModule, _delayCloseOfOverlay };
