export const idbPendingReqs = (() => {
    let dbInstance;

    function getDB() {
        if (dbInstance) return dbInstance;

        dbInstance = new Promise((resolve, reject) => {
            const openDBReq = indexedDB.open("PENDING_REQS", 1);

            openDBReq.onerror = () => {
                reject(openDBReq.error);
            };

            openDBReq.onupgradeneeded = () => {
                // First time setup: create an empty object store
                let store = openDBReq.result.createObjectStore("PendingRequests");

                // // create the Contacts object store
                // // with auto-increment id
                // let store = db.createObjectStore('PendingRequests', {
                //   autoIncrement: true
                // });

                // create an index on the type property
                let index = store.createIndex("type", "type", {
                    unique: false,
                });
            };

            openDBReq.onsuccess = () => {
                resolve(openDBReq.result);
            };
        });

        return dbInstance;
    }

    async function withStore(type, callback) {
        const db = await getDB();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction("PendingRequests", type);
            transaction.oncomplete = () => resolve();
            transaction.onerror = () => reject(transaction.error);
            callback(transaction.objectStore("PendingRequests"));
        });
    }

    return {
        async get(key) {
            let request;
            await withStore("readonly", (store) => {
                request = store.get(key);
            });
            return request.result;
        },
        set(key, value) {
            return withStore("readwrite", (store) => {
                store.put(value, key);
            });
        },
        delete(key) {
            return withStore("readwrite", (store) => {
                store.delete(key);
            });
        },
    };
})();
