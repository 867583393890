const _countriesOptions = [
    { label: "Afghanistan", number: "004", value: "Afghanistan" },
    { label: "Albania", number: "008", value: "Albania" },
    { label: "Algeria", number: "012", value: "Algeria" },
    { label: "American Samoa", number: "016", value: "American Samoa" },
    { label: "Andorra", number: "020", value: "Andorra" },
    { label: "Angola", number: "024", value: "Angola" },
    { label: "Anguilla", number: "660", value: "Anguilla" },
    { label: "Antarctica", number: "010", value: "Antarctica" },
    { label: "Antigua and Barbuda", number: "028", value: "Antigua and Barbuda" },
    { label: "Argentina", number: "032", value: "Argentina" },
    { label: "Armenia", number: "051", value: "Armenia" },
    { label: "Aruba", number: "533", value: "Aruba" },
    { label: "Australia", number: "036", value: "Australia" },
    { label: "Austria", number: "040", value: "Austria" },
    { label: "Azerbaijan", number: "031", value: "Azerbaijan" },
    { label: "Bahamas (the)", number: "044", value: "Bahamas (the)" },
    { label: "Bahrain", number: "048", value: "Bahrain" },
    { label: "Bangladesh", number: "050", value: "Bangladesh" },
    { label: "Barbados", number: "052", value: "Barbados" },
    { label: "Belarus", number: "112", value: "Belarus" },
    { label: "Belgium", number: "056", value: "Belgium" },
    { label: "Belize", number: "084", value: "Belize" },
    { label: "Benin", number: "204", value: "Benin" },
    { label: "Bermuda", number: "060", value: "Bermuda" },
    { label: "Bhutan", number: "064", value: "Bhutan" },
    { label: "Bolivia (Plurinational State of)", number: "068", value: "Bolivia (Plurinational State of)" },
    { label: "Bonaire, Sint Eustatius and Saba", number: "535", value: "Bonaire, Sint Eustatius and Saba" },
    { label: "Bosnia and Herzegovina", number: "070", value: "Bosnia and Herzegovina" },
    { label: "Botswana", number: "072", value: "Botswana" },
    { label: "Bouvet Island", number: "074", value: "Bouvet Island" },
    { label: "Brazil", number: "076", value: "Brazil" },
    { label: "British Indian Ocean Territory (the)", number: "086", value: "British Indian Ocean Territory (the)" },
    { label: "Brunei Darussalam", number: "096", value: "Brunei Darussalam" },
    { label: "Bulgaria", number: "100", value: "Bulgaria" },
    { label: "Burkina Faso", number: "854", value: "Burkina Faso" },
    { label: "Burundi", number: "108", value: "Burundi" },
    { label: "Cabo Verde", number: "132", value: "Cabo Verde" },
    { label: "Cambodia", number: "116", value: "Cambodia" },
    { label: "Cameroon", number: "120", value: "Cameroon" },
    { label: "Canada", number: "124", value: "Canada" },
    { label: "Cayman Islands (the)", number: "136", value: "Cayman Islands (the)" },
    { label: "Central African Republic (the)", number: "140", value: "Central African Republic (the)" },
    { label: "Chad", number: "148", value: "Chad" },
    { label: "Chile", number: "152", value: "Chile" },
    { label: "China", number: "156", value: "China" },
    { label: "Christmas Island", number: "162", value: "Christmas Island" },
    { label: "Cocos (Keeling) Islands (the)", number: "166", value: "Cocos (Keeling) Islands (the)" },
    { label: "Colombia", number: "170", value: "Colombia" },
    { label: "Comoros (the)", number: "174", value: "Comoros (the)" },
    { label: "Congo (the Democratic Republic of the)", number: "180", value: "Congo (the Democratic Republic of the)" },
    { label: "Congo (the)", number: "178", value: "Congo (the)" },
    { label: "Cook Islands (the)", number: "184", value: "Cook Islands (the)" },
    { label: "Costa Rica", number: "188", value: "Costa Rica" },
    { label: "Croatia", number: "191", value: "Croatia" },
    { label: "Cuba", number: "192", value: "Cuba" },
    { label: "Curaçao", number: "531", value: "Curaçao" },
    { label: "Cyprus", number: "196", value: "Cyprus" },
    { label: "Czechia", number: "203", value: "Czechia" },
    { label: "Côte d'Ivoire", number: "384", value: "Côte d'Ivoire" },
    { label: "Denmark", number: "208", value: "Denmark" },
    { label: "Djibouti", number: "262", value: "Djibouti" },
    { label: "Dominica", number: "212", value: "Dominica" },
    { label: "Dominican Republic (the)", number: "214", value: "Dominican Republic (the)" },
    { label: "Ecuador", number: "218", value: "Ecuador" },
    { label: "Egypt", number: "818", value: "Egypt" },
    { label: "El Salvador", number: "222", value: "El Salvador" },
    { label: "Equatorial Guinea", number: "226", value: "Equatorial Guinea" },
    { label: "Eritrea", number: "232", value: "Eritrea" },
    { label: "Estonia", number: "233", value: "Estonia" },
    { label: "Eswatini", number: "748", value: "Eswatini" },
    { label: "Ethiopia", number: "231", value: "Ethiopia" },
    { label: "Falkland Islands (the) [Malvinas]", number: "238", value: "Falkland Islands (the) [Malvinas]" },
    { label: "Faroe Islands (the)", number: "234", value: "Faroe Islands (the)" },
    { label: "Fiji", number: "242", value: "Fiji" },
    { label: "Finland", number: "246", value: "Finland" },
    { label: "France", number: "250", value: "France" },
    { label: "French Guiana", number: "254", value: "French Guiana" },
    { label: "French Polynesia", number: "258", value: "French Polynesia" },
    { label: "French Southern Territories (the)", number: "260", value: "French Southern Territories (the)" },
    { label: "Gabon", number: "266", value: "Gabon" },
    { label: "Gambia (the)", number: "270", value: "Gambia (the)" },
    { label: "Georgia", number: "268", value: "Georgia" },
    { label: "Germany", number: "276", value: "Germany" },
    { label: "Ghana", number: "288", value: "Ghana" },
    { label: "Gibraltar", number: "292", value: "Gibraltar" },
    { label: "Greece", number: "300", value: "Greece" },
    { label: "Greenland", number: "304", value: "Greenland" },
    { label: "Grenada", number: "308", value: "Grenada" },
    { label: "Guadeloupe", number: "312", value: "Guadeloupe" },
    { label: "Guam", number: "316", value: "Guam" },
    { label: "Guatemala", number: "320", value: "Guatemala" },
    { label: "Guernsey", number: "831", value: "Guernsey" },
    { label: "Guinea", number: "324", value: "Guinea" },
    { label: "Guinea-Bissau", number: "624", value: "Guinea-Bissau" },
    { label: "Guyana", number: "328", value: "Guyana" },
    { label: "Haiti", number: "332", value: "Haiti" },
    { label: "Heard Island and McDonald Islands", number: "334", value: "Heard Island and McDonald Islands" },
    { label: "Holy See (the)", number: "336", value: "Holy See (the)" },
    { label: "Honduras", number: "340", value: "Honduras" },
    { label: "Hong Kong", number: "344", value: "Hong Kong" },
    { label: "Hungary", number: "348", value: "Hungary" },
    { label: "Iceland", number: "352", value: "Iceland" },
    { label: "India", number: "356", value: "India" },
    { label: "Indonesia", number: "360", value: "Indonesia" },
    { label: "Iran (Islamic Republic of)", number: "364", value: "Iran (Islamic Republic of)" },
    { label: "Iraq", number: "368", value: "Iraq" },
    { label: "Ireland", number: "372", value: "Ireland" },
    { label: "Isle of Man", number: "833", value: "Isle of Man" },
    { label: "Israel", number: "376", value: "Israel" },
    { label: "Italy", number: "380", value: "Italy" },
    { label: "Jamaica", number: "388", value: "Jamaica" },
    { label: "Japan", number: "392", value: "Japan" },
    { label: "Jersey", number: "832", value: "Jersey" },
    { label: "Jordan", number: "400", value: "Jordan" },
    { label: "Kazakhstan", number: "398", value: "Kazakhstan" },
    { label: "Kenya", number: "404", value: "Kenya" },
    { label: "Kiribati", number: "296", value: "Kiribati" },
    { label: "Korea (the Democratic People's Republic of)", number: "408", value: "Korea (the Democratic People's Republic of)" },
    { label: "Korea (the Republic of)", number: "410", value: "Korea (the Republic of)" },
    { label: "Kuwait", number: "414", value: "Kuwait" },
    { label: "Kyrgyzstan", number: "417", value: "Kyrgyzstan" },
    { label: "Lao People's Democratic Republic (the)", number: "418", value: "Lao People's Democratic Republic (the)" },
    { label: "Latvia", number: "428", value: "Latvia" },
    { label: "Lebanon", number: "422", value: "Lebanon" },
    { label: "Lesotho", number: "426", value: "Lesotho" },
    { label: "Liberia", number: "430", value: "Liberia" },
    { label: "Libya", number: "434", value: "Libya" },
    { label: "Liechtenstein", number: "438", value: "Liechtenstein" },
    { label: "Lithuania", number: "440", value: "Lithuania" },
    { label: "Luxembourg", number: "442", value: "Luxembourg" },
    { label: "Macao", number: "446", value: "Macao" },
    { label: "Madagascar", number: "450", value: "Madagascar" },
    { label: "Malawi", number: "454", value: "Malawi" },
    { label: "Malaysia", number: "458", value: "Malaysia" },
    { label: "Maldives", number: "462", value: "Maldives" },
    { label: "Mali", number: "466", value: "Mali" },
    { label: "Malta", number: "470", value: "Malta" },
    { label: "Marshall Islands (the)", number: "584", value: "Marshall Islands (the)" },
    { label: "Martinique", number: "474", value: "Martinique" },
    { label: "Mauritania", number: "478", value: "Mauritania" },
    { label: "Mauritius", number: "480", value: "Mauritius" },
    { label: "Mayotte", number: "175", value: "Mayotte" },
    { label: "Mexico", number: "484", value: "Mexico" },
    { label: "Micronesia (Federated States of)", number: "583", value: "Micronesia (Federated States of)" },
    { label: "Moldova (the Republic of)", number: "498", value: "Moldova (the Republic of)" },
    { label: "Monaco", number: "492", value: "Monaco" },
    { label: "Mongolia", number: "496", value: "Mongolia" },
    { label: "Montenegro", number: "499", value: "Montenegro" },
    { label: "Montserrat", number: "500", value: "Montserrat" },
    { label: "Morocco", number: "504", value: "Morocco" },
    { label: "Mozambique", number: "508", value: "Mozambique" },
    { label: "Myanmar", number: "104", value: "Myanmar" },
    { label: "Namibia", number: "516", value: "Namibia" },
    { label: "Nauru", number: "520", value: "Nauru" },
    { label: "Nepal", number: "524", value: "Nepal" },
    { label: "Netherlands (the)", number: "528", value: "Netherlands (the)" },
    { label: "New Caledonia", number: "540", value: "New Caledonia" },
    { label: "New Zealand", number: "554", value: "New Zealand" },
    { label: "Nicaragua", number: "558", value: "Nicaragua" },
    { label: "Niger (the)", number: "562", value: "Niger (the)" },
    { label: "Nigeria", number: "566", value: "Nigeria" },
    { label: "Niue", number: "570", value: "Niue" },
    { label: "Norfolk Island", number: "574", value: "Norfolk Island" },
    { label: "Northern Mariana Islands (the)", number: "580", value: "Northern Mariana Islands (the)" },
    { label: "Norway", number: "578", value: "Norway" },
    { label: "Oman", number: "512", value: "Oman" },
    { label: "Pakistan", number: "586", value: "Pakistan" },
    { label: "Palau", number: "585", value: "Palau" },
    { label: "Palestine, State of", number: "275", value: "Palestine, State of" },
    { label: "Panama", number: "591", value: "Panama" },
    { label: "Papua New Guinea", number: "598", value: "Papua New Guinea" },
    { label: "Paraguay", number: "600", value: "Paraguay" },
    { label: "Peru", number: "604", value: "Peru" },
    { label: "Philippines (the)", number: "608", value: "Philippines (the)" },
    { label: "Pitcairn", number: "612", value: "Pitcairn" },
    { label: "Poland", number: "616", value: "Poland" },
    { label: "Portugal", number: "620", value: "Portugal" },
    { label: "Puerto Rico", number: "630", value: "Puerto Rico" },
    { label: "Qatar", number: "634", value: "Qatar" },
    { label: "Republic of North Macedonia", number: "807", value: "Republic of North Macedonia" },
    { label: "Romania", number: "642", value: "Romania" },
    { label: "Russian Federation (the)", number: "643", value: "Russian Federation (the)" },
    { label: "Rwanda", number: "646", value: "Rwanda" },
    { label: "Réunion", number: "638", value: "Réunion" },
    { label: "Saint Barthélemy", number: "652", value: "Saint Barthélemy" },
    { label: "Saint Helena, Ascension and Tristan da Cunha", number: "654", value: "Saint Helena, Ascension and Tristan da Cunha" },
    { label: "Saint Kitts and Nevis", number: "659", value: "Saint Kitts and Nevis" },
    { label: "Saint Lucia", number: "662", value: "Saint Lucia" },
    { label: "Saint Martin (French part)", number: "663", value: "Saint Martin (French part)" },
    { label: "Saint Pierre and Miquelon", number: "666", value: "Saint Pierre and Miquelon" },
    { label: "Saint Vincent and the Grenadines", number: "670", value: "Saint Vincent and the Grenadines" },
    { label: "Samoa", number: "882", value: "Samoa" },
    { label: "San Marino", number: "674", value: "San Marino" },
    { label: "Sao Tome and Principe", number: "678", value: "Sao Tome and Principe" },
    { label: "Saudi Arabia", number: "682", value: "Saudi Arabia" },
    { label: "Senegal", number: "686", value: "Senegal" },
    { label: "Serbia", number: "688", value: "Serbia" },
    { label: "Seychelles", number: "690", value: "Seychelles" },
    { label: "Sierra Leone", number: "694", value: "Sierra Leone" },
    { label: "Singapore", number: "702", value: "Singapore" },
    { label: "Sint Maarten (Dutch part)", number: "534", value: "Sint Maarten (Dutch part)" },
    { label: "Slovakia", number: "703", value: "Slovakia" },
    { label: "Slovenia", number: "705", value: "Slovenia" },
    { label: "Solomon Islands", number: "090", value: "Solomon Islands" },
    { label: "Somalia", number: "706", value: "Somalia" },
    { label: "South Africa", number: "710", value: "South Africa" },
    { label: "South Georgia and the South Sandwich Islands", number: "239", value: "South Georgia and the South Sandwich Islands" },
    { label: "South Sudan", number: "728", value: "South Sudan" },
    { label: "Spain", number: "724", value: "Spain" },
    { label: "Sri Lanka", number: "144", value: "Sri Lanka" },
    { label: "Sudan (the)", number: "729", value: "Sudan (the)" },
    { label: "Suriname", number: "740", value: "Suriname" },
    { label: "Svalbard and Jan Mayen", number: "744", value: "Svalbard and Jan Mayen" },
    { label: "Sweden", number: "752", value: "Sweden" },
    { label: "Switzerland", number: "756", value: "Switzerland" },
    { label: "Syrian Arab Republic", number: "760", value: "Syrian Arab Republic" },
    { label: "Taiwan", number: "158", value: "Taiwan" },
    { label: "Tajikistan", number: "762", value: "Tajikistan" },
    { label: "Tanzania, United Republic of", number: "834", value: "Tanzania, United Republic of" },
    { label: "Thailand", number: "764", value: "Thailand" },
    { label: "Timor-Leste", number: "626", value: "Timor-Leste" },
    { label: "Togo", number: "768", value: "Togo" },
    { label: "Tokelau", number: "772", value: "Tokelau" },
    { label: "Tonga", number: "776", value: "Tonga" },
    { label: "Trinidad and Tobago", number: "780", value: "Trinidad and Tobago" },
    { label: "Tunisia", number: "788", value: "Tunisia" },
    { label: "Turkey", number: "792", value: "Turkey" },
    { label: "Turkmenistan", number: "795", value: "Turkmenistan" },
    { label: "Turks and Caicos Islands (the)", number: "796", value: "Turks and Caicos Islands (the)" },
    { label: "Tuvalu", number: "798", value: "Tuvalu" },
    { label: "Uganda", number: "800", value: "Uganda" },
    { label: "Ukraine", number: "804", value: "Ukraine" },
    { label: "United Arab Emirates (the)", number: "784", value: "United Arab Emirates (the)" },
    { label: "United Kingdom of Great Britain", number: "827", value: "United Kingdom of Great Britain" },
    { label: "United Kingdom of Great Britain and Northern Ireland (the)", number: "826", value: "United Kingdom of Great Britain and Northern Ireland (the)" },
    { label: "United States Minor Outlying Islands (the)", number: "581", value: "United States Minor Outlying Islands (the)" },
    { label: "United States of America (the)", number: "840", value: "United States of America (the)" },
    { label: "Uruguay", number: "858", value: "Uruguay" },
    { label: "Uzbekistan", number: "860", value: "Uzbekistan" },
    { label: "Vanuatu", number: "548", value: "Vanuatu" },
    { label: "Venezuela (Bolivarian Republic of)", number: "862", value: "Venezuela (Bolivarian Republic of)" },
    { label: "Viet Nam", number: "704", value: "Viet Nam" },
    { label: "Virgin Islands (British)", number: "092", value: "Virgin Islands (British)" },
    { label: "Virgin Islands (U.S.)", number: "850", value: "Virgin Islands (U.S.)" },
    { label: "Wallis and Futuna", number: "876", value: "Wallis and Futuna" },
    { label: "Western Sahara", number: "732", value: "Western Sahara" },
    { label: "Yemen", number: "887", value: "Yemen" },
    { label: "Zambia", number: "894", value: "Zambia" },
    { label: "Zimbabwe", number: "716", value: "Zimbabwe" },
    { label: "Åland Islands", number: "248", value: "Åland Islands" },
];

const _countryCodeOptions = [
    {
        name: "Afghanistan",
        dial_code: "+93",
        code: "AF",
    },
    {
        name: "Aland Islands",
        dial_code: "+358",
        code: "AX",
    },
    {
        name: "Albania",
        dial_code: "+355",
        code: "AL",
    },
    {
        name: "Algeria",
        dial_code: "+213",
        code: "DZ",
    },
    {
        name: "AmericanSamoa",
        dial_code: "+1684",
        code: "AS",
    },
    {
        name: "Andorra",
        dial_code: "+376",
        code: "AD",
    },
    {
        name: "Angola",
        dial_code: "+244",
        code: "AO",
    },
    {
        name: "Anguilla",
        dial_code: "+1264",
        code: "AI",
    },
    {
        name: "Antarctica",
        dial_code: "+672",
        code: "AQ",
    },
    {
        name: "Antigua and Barbuda",
        dial_code: "+1268",
        code: "AG",
    },
    {
        name: "Argentina",
        dial_code: "+54",
        code: "AR",
    },
    {
        name: "Armenia",
        dial_code: "+374",
        code: "AM",
    },
    {
        name: "Aruba",
        dial_code: "+297",
        code: "AW",
    },
    {
        name: "Australia",
        dial_code: "+61",
        code: "AU",
    },
    {
        name: "Austria",
        dial_code: "+43",
        code: "AT",
    },
    {
        name: "Azerbaijan",
        dial_code: "+994",
        code: "AZ",
    },
    {
        name: "Bahamas",
        dial_code: "+1242",
        code: "BS",
    },
    {
        name: "Bahrain",
        dial_code: "+973",
        code: "BH",
    },
    {
        name: "Bangladesh",
        dial_code: "+880",
        code: "BD",
    },
    {
        name: "Barbados",
        dial_code: "+1246",
        code: "BB",
    },
    {
        name: "Belarus",
        dial_code: "+375",
        code: "BY",
    },
    {
        name: "Belgium",
        dial_code: "+32",
        code: "BE",
    },
    {
        name: "Belize",
        dial_code: "+501",
        code: "BZ",
    },
    {
        name: "Benin",
        dial_code: "+229",
        code: "BJ",
    },
    {
        name: "Bermuda",
        dial_code: "+1441",
        code: "BM",
    },
    {
        name: "Bhutan",
        dial_code: "+975",
        code: "BT",
    },
    {
        name: "Bolivia, Plurinational State of",
        dial_code: "+591",
        code: "BO",
    },
    {
        name: "Bosnia and Herzegovina",
        dial_code: "+387",
        code: "BA",
    },
    {
        name: "Botswana",
        dial_code: "+267",
        code: "BW",
    },
    {
        name: "Brazil",
        dial_code: "+55",
        code: "BR",
    },
    {
        name: "British Indian Ocean Territory",
        dial_code: "+246",
        code: "IO",
    },
    {
        name: "Brunei Darussalam",
        dial_code: "+673",
        code: "BN",
    },
    {
        name: "Bulgaria",
        dial_code: "+359",
        code: "BG",
    },
    {
        name: "Burkina Faso",
        dial_code: "+226",
        code: "BF",
    },
    {
        name: "Burundi",
        dial_code: "+257",
        code: "BI",
    },
    {
        name: "Cambodia",
        dial_code: "+855",
        code: "KH",
    },
    {
        name: "Cameroon",
        dial_code: "+237",
        code: "CM",
    },
    {
        name: "Canada",
        dial_code: "+1",
        code: "CA",
    },
    {
        name: "Cape Verde",
        dial_code: "+238",
        code: "CV",
    },
    {
        name: "Cayman Islands",
        dial_code: "+ 345",
        code: "KY",
    },
    {
        name: "Central African Republic",
        dial_code: "+236",
        code: "CF",
    },
    {
        name: "Chad",
        dial_code: "+235",
        code: "TD",
    },
    {
        name: "Chile",
        dial_code: "+56",
        code: "CL",
    },
    {
        name: "China",
        dial_code: "+86",
        code: "CN",
    },
    {
        name: "Christmas Island",
        dial_code: "+61",
        code: "CX",
    },
    {
        name: "Cocos (Keeling) Islands",
        dial_code: "+61",
        code: "CC",
    },
    {
        name: "Colombia",
        dial_code: "+57",
        code: "CO",
    },
    {
        name: "Comoros",
        dial_code: "+269",
        code: "KM",
    },
    {
        name: "Congo",
        dial_code: "+242",
        code: "CG",
    },
    {
        name: "Congo, The Democratic Republic of the Congo",
        dial_code: "+243",
        code: "CD",
    },
    {
        name: "Cook Islands",
        dial_code: "+682",
        code: "CK",
    },
    {
        name: "Costa Rica",
        dial_code: "+506",
        code: "CR",
    },
    {
        name: "Cote d'Ivoire",
        dial_code: "+225",
        code: "CI",
    },
    {
        name: "Croatia",
        dial_code: "+385",
        code: "HR",
    },
    {
        name: "Cuba",
        dial_code: "+53",
        code: "CU",
    },
    {
        name: "Cyprus",
        dial_code: "+357",
        code: "CY",
    },
    {
        name: "Czech Republic",
        dial_code: "+420",
        code: "CZ",
    },
    {
        name: "Denmark",
        dial_code: "+45",
        code: "DK",
    },
    {
        name: "Djibouti",
        dial_code: "+253",
        code: "DJ",
    },
    {
        name: "Dominica",
        dial_code: "+1767",
        code: "DM",
    },
    {
        name: "Dominican Republic",
        dial_code: "+1849",
        code: "DO",
    },
    {
        name: "Ecuador",
        dial_code: "+593",
        code: "EC",
    },
    {
        name: "Egypt",
        dial_code: "+20",
        code: "EG",
    },
    {
        name: "El Salvador",
        dial_code: "+503",
        code: "SV",
    },
    {
        name: "Equatorial Guinea",
        dial_code: "+240",
        code: "GQ",
    },
    {
        name: "Eritrea",
        dial_code: "+291",
        code: "ER",
    },
    {
        name: "Estonia",
        dial_code: "+372",
        code: "EE",
    },
    {
        name: "Ethiopia",
        dial_code: "+251",
        code: "ET",
    },
    {
        name: "Falkland Islands (Malvinas)",
        dial_code: "+500",
        code: "FK",
    },
    {
        name: "Faroe Islands",
        dial_code: "+298",
        code: "FO",
    },
    {
        name: "Fiji",
        dial_code: "+679",
        code: "FJ",
    },
    {
        name: "Finland",
        dial_code: "+358",
        code: "FI",
    },
    {
        name: "France",
        dial_code: "+33",
        code: "FR",
    },
    {
        name: "French Guiana",
        dial_code: "+594",
        code: "GF",
    },
    {
        name: "French Polynesia",
        dial_code: "+689",
        code: "PF",
    },
    {
        name: "Gabon",
        dial_code: "+241",
        code: "GA",
    },
    {
        name: "Gambia",
        dial_code: "+220",
        code: "GM",
    },
    {
        name: "Georgia",
        dial_code: "+995",
        code: "GE",
    },
    {
        name: "Germany",
        dial_code: "+49",
        code: "DE",
    },
    {
        name: "Ghana",
        dial_code: "+233",
        code: "GH",
    },
    {
        name: "Gibraltar",
        dial_code: "+350",
        code: "GI",
    },
    {
        name: "Greece",
        dial_code: "+30",
        code: "GR",
    },
    {
        name: "Greenland",
        dial_code: "+299",
        code: "GL",
    },
    {
        name: "Grenada",
        dial_code: "+1473",
        code: "GD",
    },
    {
        name: "Guadeloupe",
        dial_code: "+590",
        code: "GP",
    },
    {
        name: "Guam",
        dial_code: "+1671",
        code: "GU",
    },
    {
        name: "Guatemala",
        dial_code: "+502",
        code: "GT",
    },
    {
        name: "Guernsey",
        dial_code: "+44",
        code: "GG",
    },
    {
        name: "Guinea",
        dial_code: "+224",
        code: "GN",
    },
    {
        name: "Guinea-Bissau",
        dial_code: "+245",
        code: "GW",
    },
    {
        name: "Guyana",
        dial_code: "+595",
        code: "GY",
    },
    {
        name: "Haiti",
        dial_code: "+509",
        code: "HT",
    },
    {
        name: "Holy See (Vatican City State)",
        dial_code: "+379",
        code: "VA",
    },
    {
        name: "Honduras",
        dial_code: "+504",
        code: "HN",
    },
    {
        name: "Hong Kong",
        dial_code: "+852",
        code: "HK",
    },
    {
        name: "Hungary",
        dial_code: "+36",
        code: "HU",
    },
    {
        name: "Iceland",
        dial_code: "+354",
        code: "IS",
    },
    {
        name: "India",
        dial_code: "+91",
        code: "IN",
    },
    {
        name: "Indonesia",
        dial_code: "+62",
        code: "ID",
    },
    {
        name: "Iran, Islamic Republic of Persian Gulf",
        dial_code: "+98",
        code: "IR",
    },
    {
        name: "Iraq",
        dial_code: "+964",
        code: "IQ",
    },
    {
        name: "Ireland",
        dial_code: "+353",
        code: "IE",
    },
    {
        name: "Isle of Man",
        dial_code: "+44",
        code: "IM",
    },
    {
        name: "Israel",
        dial_code: "+972",
        code: "IL",
    },
    {
        name: "Italy",
        dial_code: "+39",
        code: "IT",
    },
    {
        name: "Jamaica",
        dial_code: "+1876",
        code: "JM",
    },
    {
        name: "Japan",
        dial_code: "+81",
        code: "JP",
    },
    {
        name: "Jersey",
        dial_code: "+44",
        code: "JE",
    },
    {
        name: "Jordan",
        dial_code: "+962",
        code: "JO",
    },
    {
        name: "Kazakhstan",
        dial_code: "+77",
        code: "KZ",
    },
    {
        name: "Kenya",
        dial_code: "+254",
        code: "KE",
    },
    {
        name: "Kiribati",
        dial_code: "+686",
        code: "KI",
    },
    {
        name: "Korea, Democratic People's Republic of Korea",
        dial_code: "+850",
        code: "KP",
    },
    {
        name: "Korea, Republic of South Korea",
        dial_code: "+82",
        code: "KR",
    },
    {
        name: "Kuwait",
        dial_code: "+965",
        code: "KW",
    },
    {
        name: "Kyrgyzstan",
        dial_code: "+996",
        code: "KG",
    },
    {
        name: "Laos",
        dial_code: "+856",
        code: "LA",
    },
    {
        name: "Latvia",
        dial_code: "+371",
        code: "LV",
    },
    {
        name: "Lebanon",
        dial_code: "+961",
        code: "LB",
    },
    {
        name: "Lesotho",
        dial_code: "+266",
        code: "LS",
    },
    {
        name: "Liberia",
        dial_code: "+231",
        code: "LR",
    },
    {
        name: "Libyan Arab Jamahiriya",
        dial_code: "+218",
        code: "LY",
    },
    {
        name: "Liechtenstein",
        dial_code: "+423",
        code: "LI",
    },
    {
        name: "Lithuania",
        dial_code: "+370",
        code: "LT",
    },
    {
        name: "Luxembourg",
        dial_code: "+352",
        code: "LU",
    },
    {
        name: "Macao",
        dial_code: "+853",
        code: "MO",
    },
    {
        name: "Macedonia",
        dial_code: "+389",
        code: "MK",
    },
    {
        name: "Madagascar",
        dial_code: "+261",
        code: "MG",
    },
    {
        name: "Malawi",
        dial_code: "+265",
        code: "MW",
    },
    {
        name: "Malaysia",
        dial_code: "+60",
        code: "MY",
    },
    {
        name: "Maldives",
        dial_code: "+960",
        code: "MV",
    },
    {
        name: "Mali",
        dial_code: "+223",
        code: "ML",
    },
    {
        name: "Malta",
        dial_code: "+356",
        code: "MT",
    },
    {
        name: "Marshall Islands",
        dial_code: "+692",
        code: "MH",
    },
    {
        name: "Martinique",
        dial_code: "+596",
        code: "MQ",
    },
    {
        name: "Mauritania",
        dial_code: "+222",
        code: "MR",
    },
    {
        name: "Mauritius",
        dial_code: "+230",
        code: "MU",
    },
    {
        name: "Mayotte",
        dial_code: "+262",
        code: "YT",
    },
    {
        name: "Mexico",
        dial_code: "+52",
        code: "MX",
    },
    {
        name: "Micronesia, Federated States of Micronesia",
        dial_code: "+691",
        code: "FM",
    },
    {
        name: "Moldova",
        dial_code: "+373",
        code: "MD",
    },
    {
        name: "Monaco",
        dial_code: "+377",
        code: "MC",
    },
    {
        name: "Mongolia",
        dial_code: "+976",
        code: "MN",
    },
    {
        name: "Montenegro",
        dial_code: "+382",
        code: "ME",
    },
    {
        name: "Montserrat",
        dial_code: "+1664",
        code: "MS",
    },
    {
        name: "Morocco",
        dial_code: "+212",
        code: "MA",
    },
    {
        name: "Mozambique",
        dial_code: "+258",
        code: "MZ",
    },
    {
        name: "Myanmar",
        dial_code: "+95",
        code: "MM",
    },
    {
        name: "Namibia",
        dial_code: "+264",
        code: "NA",
    },
    {
        name: "Nauru",
        dial_code: "+674",
        code: "NR",
    },
    {
        name: "Nepal",
        dial_code: "+977",
        code: "NP",
    },
    {
        name: "Netherlands",
        dial_code: "+31",
        code: "NL",
    },
    {
        name: "Netherlands Antilles",
        dial_code: "+599",
        code: "AN",
    },
    {
        name: "New Caledonia",
        dial_code: "+687",
        code: "NC",
    },
    {
        name: "New Zealand",
        dial_code: "+64",
        code: "NZ",
    },
    {
        name: "Nicaragua",
        dial_code: "+505",
        code: "NI",
    },
    {
        name: "Niger",
        dial_code: "+227",
        code: "NE",
    },
    {
        name: "Nigeria",
        dial_code: "+234",
        code: "NG",
    },
    {
        name: "Niue",
        dial_code: "+683",
        code: "NU",
    },
    {
        name: "Norfolk Island",
        dial_code: "+672",
        code: "NF",
    },
    {
        name: "Northern Mariana Islands",
        dial_code: "+1670",
        code: "MP",
    },
    {
        name: "Norway",
        dial_code: "+47",
        code: "NO",
    },
    {
        name: "Oman",
        dial_code: "+968",
        code: "OM",
    },
    {
        name: "Pakistan",
        dial_code: "+92",
        code: "PK",
    },
    {
        name: "Palau",
        dial_code: "+680",
        code: "PW",
    },
    {
        name: "Palestinian Territory, Occupied",
        dial_code: "+970",
        code: "PS",
    },
    {
        name: "Panama",
        dial_code: "+507",
        code: "PA",
    },
    {
        name: "Papua New Guinea",
        dial_code: "+675",
        code: "PG",
    },
    {
        name: "Paraguay",
        dial_code: "+595",
        code: "PY",
    },
    {
        name: "Peru",
        dial_code: "+51",
        code: "PE",
    },
    {
        name: "Philippines",
        dial_code: "+63",
        code: "PH",
    },
    {
        name: "Pitcairn",
        dial_code: "+872",
        code: "PN",
    },
    {
        name: "Poland",
        dial_code: "+48",
        code: "PL",
    },
    {
        name: "Portugal",
        dial_code: "+351",
        code: "PT",
    },
    {
        name: "Puerto Rico",
        dial_code: "+1939",
        code: "PR",
    },
    {
        name: "Qatar",
        dial_code: "+974",
        code: "QA",
    },
    {
        name: "Romania",
        dial_code: "+40",
        code: "RO",
    },
    {
        name: "Russia",
        dial_code: "+7",
        code: "RU",
    },
    {
        name: "Rwanda",
        dial_code: "+250",
        code: "RW",
    },
    {
        name: "Reunion",
        dial_code: "+262",
        code: "RE",
    },
    {
        name: "Saint Barthelemy",
        dial_code: "+590",
        code: "BL",
    },
    {
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        dial_code: "+290",
        code: "SH",
    },
    {
        name: "Saint Kitts and Nevis",
        dial_code: "+1869",
        code: "KN",
    },
    {
        name: "Saint Lucia",
        dial_code: "+1758",
        code: "LC",
    },
    {
        name: "Saint Martin",
        dial_code: "+590",
        code: "MF",
    },
    {
        name: "Saint Pierre and Miquelon",
        dial_code: "+508",
        code: "PM",
    },
    {
        name: "Saint Vincent and the Grenadines",
        dial_code: "+1784",
        code: "VC",
    },
    {
        name: "Samoa",
        dial_code: "+685",
        code: "WS",
    },
    {
        name: "San Marino",
        dial_code: "+378",
        code: "SM",
    },
    {
        name: "Sao Tome and Principe",
        dial_code: "+239",
        code: "ST",
    },
    {
        name: "Saudi Arabia",
        dial_code: "+966",
        code: "SA",
    },
    {
        name: "Senegal",
        dial_code: "+221",
        code: "SN",
    },
    {
        name: "Serbia",
        dial_code: "+381",
        code: "RS",
    },
    {
        name: "Seychelles",
        dial_code: "+248",
        code: "SC",
    },
    {
        name: "Sierra Leone",
        dial_code: "+232",
        code: "SL",
    },
    {
        name: "Singapore",
        dial_code: "+65",
        code: "SG",
    },
    {
        name: "Slovakia",
        dial_code: "+421",
        code: "SK",
    },
    {
        name: "Slovenia",
        dial_code: "+386",
        code: "SI",
    },
    {
        name: "Solomon Islands",
        dial_code: "+677",
        code: "SB",
    },
    {
        name: "Somalia",
        dial_code: "+252",
        code: "SO",
    },
    {
        name: "South Africa",
        dial_code: "+27",
        code: "ZA",
    },
    {
        name: "South Sudan",
        dial_code: "+211",
        code: "SS",
    },
    {
        name: "South Georgia and the South Sandwich Islands",
        dial_code: "+500",
        code: "GS",
    },
    {
        name: "Spain",
        dial_code: "+34",
        code: "ES",
    },
    {
        name: "Sri Lanka",
        dial_code: "+94",
        code: "LK",
    },
    {
        name: "Sudan",
        dial_code: "+249",
        code: "SD",
    },
    {
        name: "Suriname",
        dial_code: "+597",
        code: "SR",
    },
    {
        name: "Svalbard and Jan Mayen",
        dial_code: "+47",
        code: "SJ",
    },
    {
        name: "Swaziland",
        dial_code: "+268",
        code: "SZ",
    },
    {
        name: "Sweden",
        dial_code: "+46",
        code: "SE",
    },
    {
        name: "Switzerland",
        dial_code: "+41",
        code: "CH",
    },
    {
        name: "Syrian Arab Republic",
        dial_code: "+963",
        code: "SY",
    },
    {
        name: "Taiwan",
        dial_code: "+886",
        code: "TW",
    },
    {
        name: "Tajikistan",
        dial_code: "+992",
        code: "TJ",
    },
    {
        name: "Tanzania, United Republic of Tanzania",
        dial_code: "+255",
        code: "TZ",
    },
    {
        name: "Thailand",
        dial_code: "+66",
        code: "TH",
    },
    {
        name: "Timor-Leste",
        dial_code: "+670",
        code: "TL",
    },
    {
        name: "Togo",
        dial_code: "+228",
        code: "TG",
    },
    {
        name: "Tokelau",
        dial_code: "+690",
        code: "TK",
    },
    {
        name: "Tonga",
        dial_code: "+676",
        code: "TO",
    },
    {
        name: "Trinidad and Tobago",
        dial_code: "+1868",
        code: "TT",
    },
    {
        name: "Tunisia",
        dial_code: "+216",
        code: "TN",
    },
    {
        name: "Turkey",
        dial_code: "+90",
        code: "TR",
    },
    {
        name: "Turkmenistan",
        dial_code: "+993",
        code: "TM",
    },
    {
        name: "Turks and Caicos Islands",
        dial_code: "+1649",
        code: "TC",
    },
    {
        name: "Tuvalu",
        dial_code: "+688",
        code: "TV",
    },
    {
        name: "Uganda",
        dial_code: "+256",
        code: "UG",
    },
    {
        name: "Ukraine",
        dial_code: "+380",
        code: "UA",
    },
    {
        name: "United Arab Emirates",
        dial_code: "+971",
        code: "AE",
    },
    {
        name: "United Kingdom",
        dial_code: "+44",
        code: "GB",
    },
    {
        name: "United Kingdom of Great Britain",
        dial_code: "+44",
        code: "GB",
    },
    {
        name: "United Kingdom of Great Britain and Northern Ireland (the)",
        dial_code: "+44",
        code: "GB",
    },
    {
        name: "United States of America (the)",
        dial_code: "+1",
        code: "US",
    },
    {
        name: "United States of America USA",
        dial_code: "+1",
        code: "US",
    },
    {
        name: "USA",
        dial_code: "+1",
        code: "US",
    },
    {
        name: "Uruguay",
        dial_code: "+598",
        code: "UY",
    },
    {
        name: "Uzbekistan",
        dial_code: "+998",
        code: "UZ",
    },
    {
        name: "Vanuatu",
        dial_code: "+678",
        code: "VU",
    },
    {
        name: "Venezuela, Bolivarian Republic of Venezuela",
        dial_code: "+58",
        code: "VE",
    },
    {
        name: "Vietnam",
        dial_code: "+84",
        code: "VN",
    },
    {
        name: "Virgin Islands, British",
        dial_code: "+1284",
        code: "VG",
    },
    {
        name: "Virgin Islands, U.S.",
        dial_code: "+1340",
        code: "VI",
    },
    {
        name: "Wallis and Futuna",
        dial_code: "+681",
        code: "WF",
    },
    {
        name: "Yemen",
        dial_code: "+967",
        code: "YE",
    },
    {
        name: "Zambia",
        dial_code: "+260",
        code: "ZM",
    },
    {
        name: "Zimbabwe",
        dial_code: "+263",
        code: "ZW",
    },
];

const _currencyMap = [
    {
        symbol: "$",
        name: "US Dollar",
        symbol_native: "$",
        code: "USD",
    },
    {
        symbol: "CA$",
        name: "Canadian Dollar",
        symbol_native: "$",
        code: "CAD",
    },
    {
        symbol: "€",
        name: "Euro",
        symbol_native: "€",
        code: "EUR",
    },
    {
        symbol: "AED",
        name: "United Arab Emirates Dirham",
        symbol_native: "د.إ.‏",
        code: "AED",
    },
    {
        symbol: "Af",
        name: "Afghan Afghani",
        symbol_native: "؋",
        code: "AFN",
    },
    {
        symbol: "ALL",
        name: "Albanian Lek",
        symbol_native: "Lek",
        code: "ALL",
    },
    {
        symbol: "AMD",
        name: "Armenian Dram",
        symbol_native: "դր.",
        code: "AMD",
    },
    {
        symbol: "AR$",
        name: "Argentine Peso",
        symbol_native: "$",
        code: "ARS",
    },
    {
        symbol: "AU$",
        name: "Australian Dollar",
        symbol_native: "$",
        code: "AUD",
    },
    {
        symbol: "man.",
        name: "Azerbaijani Manat",
        symbol_native: "ман.",
        code: "AZN",
    },
    {
        symbol: "KM",
        name: "Bosnia-Herzegovina Convertible Mark",
        symbol_native: "KM",
        code: "BAM",
    },
    {
        symbol: "Tk",
        name: "Bangladeshi Taka",
        symbol_native: "৳",
        code: "BDT",
    },
    {
        symbol: "BGN",
        name: "Bulgarian Lev",
        symbol_native: "лв.",
        code: "BGN",
    },
    {
        symbol: "BD",
        name: "Bahraini Dinar",
        symbol_native: "د.ب.‏",
        code: "BHD",
    },
    {
        symbol: "FBu",
        name: "Burundian Franc",
        symbol_native: "FBu",
        code: "BIF",
    },
    {
        symbol: "BN$",
        name: "Brunei Dollar",
        symbol_native: "$",
        code: "BND",
    },
    {
        symbol: "Bs",
        name: "Bolivian Boliviano",
        symbol_native: "Bs",
        code: "BOB",
    },
    {
        symbol: "R$",
        name: "Brazilian Real",
        symbol_native: "R$",
        code: "BRL",
    },
    {
        symbol: "BWP",
        name: "Botswanan Pula",
        symbol_native: "P",
        code: "BWP",
    },
    {
        symbol: "Br",
        name: "Belarusian Ruble",
        symbol_native: "руб.",
        code: "BYN",
    },
    {
        symbol: "BZ$",
        name: "Belize Dollar",
        symbol_native: "$",
        code: "BZD",
    },
    {
        symbol: "CDF",
        name: "Congolese Franc",
        symbol_native: "FrCD",
        code: "CDF",
    },
    {
        symbol: "CHF",
        name: "Swiss Franc",
        symbol_native: "CHF",
        code: "CHF",
    },
    {
        symbol: "CL$",
        name: "Chilean Peso",
        symbol_native: "$",
        code: "CLP",
    },
    {
        symbol: "CN¥",
        name: "Chinese Yuan",
        symbol_native: "CN¥",
        code: "CNY",
    },
    {
        symbol: "CO$",
        name: "Colombian Peso",
        symbol_native: "$",
        code: "COP",
    },
    {
        symbol: "₡",
        name: "Costa Rican Colón",
        symbol_native: "₡",
        code: "CRC",
    },
    {
        symbol: "CV$",
        name: "Cape Verdean Escudo",
        symbol_native: "CV$",
        code: "CVE",
    },
    {
        symbol: "Kč",
        name: "Czech Republic Koruna",
        symbol_native: "Kč",
        code: "CZK",
    },
    {
        symbol: "Fdj",
        name: "Djiboutian Franc",
        symbol_native: "Fdj",
        code: "DJF",
    },
    {
        symbol: "Dkr",
        name: "Danish Krone",
        symbol_native: "kr",
        code: "DKK",
    },
    {
        symbol: "RD$",
        name: "Dominican Peso",
        symbol_native: "RD$",
        code: "DOP",
    },
    {
        symbol: "DA",
        name: "Algerian Dinar",
        symbol_native: "د.ج.‏",
        code: "DZD",
    },
    {
        symbol: "Ekr",
        name: "Estonian Kroon",
        symbol_native: "kr",
        code: "EEK",
    },
    {
        symbol: "EGP",
        name: "Egyptian Pound",
        symbol_native: "ج.م.‏",
        code: "EGP",
    },
    {
        symbol: "Nfk",
        name: "Eritrean Nakfa",
        symbol_native: "Nfk",
        code: "ERN",
    },
    {
        symbol: "Br",
        name: "Ethiopian Birr",
        symbol_native: "Br",
        code: "ETB",
    },
    {
        symbol: "£",
        name: "British Pound Sterling",
        symbol_native: "£",
        code: "GBP",
    },
    {
        symbol: "GEL",
        name: "Georgian Lari",
        symbol_native: "GEL",
        code: "GEL",
    },
    {
        symbol: "GH₵",
        name: "Ghanaian Cedi",
        symbol_native: "GH₵",
        code: "GHS",
    },
    {
        symbol: "FG",
        name: "Guinean Franc",
        symbol_native: "FG",
        code: "GNF",
    },
    {
        symbol: "GTQ",
        name: "Guatemalan Quetzal",
        symbol_native: "Q",
        code: "GTQ",
    },
    {
        symbol: "HK$",
        name: "Hong Kong Dollar",
        symbol_native: "$",
        code: "HKD",
    },
    {
        symbol: "HNL",
        name: "Honduran Lempira",
        symbol_native: "L",
        code: "HNL",
    },
    {
        symbol: "kn",
        name: "Croatian Kuna",
        symbol_native: "kn",
        code: "HRK",
    },
    {
        symbol: "Ft",
        name: "Hungarian Forint",
        symbol_native: "Ft",
        code: "HUF",
    },
    {
        symbol: "Rp",
        name: "Indonesian Rupiah",
        symbol_native: "Rp",
        code: "IDR",
    },
    {
        symbol: "₪",
        name: "Israeli New Sheqel",
        symbol_native: "₪",
        code: "ILS",
    },
    {
        symbol: "Rs",
        name: "Indian Rupee",
        symbol_native: "টকা",
        code: "INR",
    },
    {
        symbol: "IQD",
        name: "Iraqi Dinar",
        symbol_native: "د.ع.‏",
        code: "IQD",
    },
    {
        symbol: "IRR",
        name: "Iranian Rial",
        symbol_native: "﷼",
        code: "IRR",
    },
    {
        symbol: "Ikr",
        name: "Icelandic Króna",
        symbol_native: "kr",
        code: "ISK",
    },
    {
        symbol: "J$",
        name: "Jamaican Dollar",
        symbol_native: "$",
        code: "JMD",
    },
    {
        symbol: "JD",
        name: "Jordanian Dinar",
        symbol_native: "د.أ.‏",
        code: "JOD",
    },
    {
        symbol: "¥",
        name: "Japanese Yen",
        symbol_native: "￥",
        code: "JPY",
    },
    {
        symbol: "Ksh",
        name: "Kenyan Shilling",
        symbol_native: "Ksh",
        code: "KES",
    },
    {
        symbol: "KHR",
        name: "Cambodian Riel",
        symbol_native: "៛",
        code: "KHR",
    },
    {
        symbol: "CF",
        name: "Comorian Franc",
        symbol_native: "FC",
        code: "KMF",
    },
    {
        symbol: "₩",
        name: "South Korean Won",
        symbol_native: "₩",
        code: "KRW",
    },
    {
        symbol: "KD",
        name: "Kuwaiti Dinar",
        symbol_native: "د.ك.‏",
        code: "KWD",
    },
    {
        symbol: "KZT",
        name: "Kazakhstani Tenge",
        symbol_native: "тңг.",
        code: "KZT",
    },
    {
        symbol: "L.L.",
        name: "Lebanese Pound",
        symbol_native: "ل.ل.‏",
        code: "LBP",
    },
    {
        symbol: "SLRs",
        name: "Sri Lankan Rupee",
        symbol_native: "SL Re",
        code: "LKR",
    },
    {
        symbol: "Lt",
        name: "Lithuanian Litas",
        symbol_native: "Lt",
        code: "LTL",
    },
    {
        symbol: "Ls",
        name: "Latvian Lats",
        symbol_native: "Ls",
        code: "LVL",
    },
    {
        symbol: "LD",
        name: "Libyan Dinar",
        symbol_native: "د.ل.‏",
        code: "LYD",
    },
    {
        symbol: "MAD",
        name: "Moroccan Dirham",
        symbol_native: "د.م.‏",
        code: "MAD",
    },
    {
        symbol: "MDL",
        name: "Moldovan Leu",
        symbol_native: "MDL",
        code: "MDL",
    },
    {
        symbol: "MGA",
        name: "Malagasy Ariary",
        symbol_native: "MGA",
        code: "MGA",
    },
    {
        symbol: "MKD",
        name: "Macedonian Denar",
        symbol_native: "MKD",
        code: "MKD",
    },
    {
        symbol: "MMK",
        name: "Myanma Kyat",
        symbol_native: "K",
        code: "MMK",
    },
    {
        symbol: "MOP$",
        name: "Macanese Pataca",
        symbol_native: "MOP$",
        code: "MOP",
    },
    {
        symbol: "MURs",
        name: "Mauritian Rupee",
        symbol_native: "MURs",
        code: "MUR",
    },
    {
        symbol: "MX$",
        name: "Mexican Peso",
        symbol_native: "$",
        code: "MXN",
    },
    {
        symbol: "RM",
        name: "Malaysian Ringgit",
        symbol_native: "RM",
        code: "MYR",
    },
    {
        symbol: "MTn",
        name: "Mozambican Metical",
        symbol_native: "MTn",
        code: "MZN",
    },
    {
        symbol: "N$",
        name: "Namibian Dollar",
        symbol_native: "N$",
        code: "NAD",
    },
    {
        symbol: "₦",
        name: "Nigerian Naira",
        symbol_native: "₦",
        code: "NGN",
    },
    {
        symbol: "C$",
        name: "Nicaraguan Córdoba",
        symbol_native: "C$",
        code: "NIO",
    },
    {
        symbol: "Nkr",
        name: "Norwegian Krone",
        symbol_native: "kr",
        code: "NOK",
    },
    {
        symbol: "NPRs",
        name: "Nepalese Rupee",
        symbol_native: "नेरू",
        code: "NPR",
    },
    {
        symbol: "NZ$",
        name: "New Zealand Dollar",
        symbol_native: "$",
        code: "NZD",
    },
    {
        symbol: "OMR",
        name: "Omani Rial",
        symbol_native: "ر.ع.‏",
        code: "OMR",
    },
    {
        symbol: "B/.",
        name: "Panamanian Balboa",
        symbol_native: "B/.",
        code: "PAB",
    },
    {
        symbol: "S/.",
        name: "Peruvian Nuevo Sol",
        symbol_native: "S/.",
        code: "PEN",
    },
    {
        symbol: "₱",
        name: "Philippine Peso",
        symbol_native: "₱",
        code: "PHP",
    },
    {
        symbol: "PKRs",
        name: "Pakistani Rupee",
        symbol_native: "₨",
        code: "PKR",
    },
    {
        symbol: "zł",
        name: "Polish Zloty",
        symbol_native: "zł",
        code: "PLN",
    },
    {
        symbol: "₲",
        name: "Paraguayan Guarani",
        symbol_native: "₲",
        code: "PYG",
    },
    {
        symbol: "QR",
        name: "Qatari Rial",
        symbol_native: "ر.ق.‏",
        code: "QAR",
    },
    {
        symbol: "RON",
        name: "Romanian Leu",
        symbol_native: "RON",
        code: "RON",
    },
    {
        symbol: "din.",
        name: "Serbian Dinar",
        symbol_native: "дин.",
        code: "RSD",
    },
    {
        symbol: "RUB",
        name: "Russian Ruble",
        symbol_native: "₽.",
        code: "RUB",
    },
    {
        symbol: "RWF",
        name: "Rwandan Franc",
        symbol_native: "FR",
        code: "RWF",
    },
    {
        symbol: "SR",
        name: "Saudi Riyal",
        symbol_native: "ر.س.‏",
        code: "SAR",
    },
    {
        symbol: "SDG",
        name: "Sudanese Pound",
        symbol_native: "SDG",
        code: "SDG",
    },
    {
        symbol: "Skr",
        name: "Swedish Krona",
        symbol_native: "kr",
        code: "SEK",
    },
    {
        symbol: "S$",
        name: "Singapore Dollar",
        symbol_native: "$",
        code: "SGD",
    },
    {
        symbol: "Ssh",
        name: "Somali Shilling",
        symbol_native: "Ssh",
        code: "SOS",
    },
    {
        symbol: "SY£",
        name: "Syrian Pound",
        symbol_native: "ل.س.‏",
        code: "SYP",
    },
    {
        symbol: "฿",
        name: "Thai Baht",
        symbol_native: "฿",
        code: "THB",
    },
    {
        symbol: "DT",
        name: "Tunisian Dinar",
        symbol_native: "د.ت.‏",
        code: "TND",
    },
    {
        symbol: "T$",
        name: "Tongan Paʻanga",
        symbol_native: "T$",
        code: "TOP",
    },
    {
        symbol: "TL",
        name: "Turkish Lira",
        symbol_native: "TL",
        code: "TRY",
    },
    {
        symbol: "TT$",
        name: "Trinidad and Tobago Dollar",
        symbol_native: "$",
        code: "TTD",
    },
    {
        symbol: "NT$",
        name: "New Taiwan Dollar",
        symbol_native: "NT$",
        code: "TWD",
    },
    {
        symbol: "TSh",
        name: "Tanzanian Shilling",
        symbol_native: "TSh",
        code: "TZS",
    },
    {
        symbol: "₴",
        name: "Ukrainian Hryvnia",
        symbol_native: "₴",
        code: "UAH",
    },
    {
        symbol: "USh",
        name: "Ugandan Shilling",
        symbol_native: "USh",
        code: "UGX",
    },
    {
        symbol: "$U",
        name: "Uruguayan Peso",
        symbol_native: "$",
        code: "UYU",
    },
    {
        symbol: "UZS",
        name: "Uzbekistan Som",
        symbol_native: "UZS",
        code: "UZS",
    },
    {
        symbol: "Bs.F.",
        name: "Venezuelan Bolívar",
        symbol_native: "Bs.F.",
        code: "VEF",
    },
    {
        symbol: "₫",
        name: "Vietnamese Dong",
        symbol_native: "₫",
        code: "VND",
    },
    {
        symbol: "FCFA",
        name: "CFA Franc BEAC",
        symbol_native: "FCFA",
        code: "XAF",
    },
    {
        symbol: "CFA",
        name: "CFA Franc BCEAO",
        symbol_native: "CFA",
        code: "XOF",
    },
    {
        symbol: "YR",
        name: "Yemeni Rial",
        symbol_native: "ر.ي.‏",
        code: "YER",
    },
    {
        symbol: "R",
        name: "South African Rand",
        symbol_native: "R",
        code: "ZAR",
    },
    {
        symbol: "ZK",
        name: "Zambian Kwacha",
        symbol_native: "ZK",
        code: "ZMK",
    },
    {
        symbol: "ZWL$",
        name: "Zimbabwean Dollar",
        symbol_native: "ZWL$",
        code: "ZWL",
    },
];

const _entity_linked_bank_data = [
    {
        id: 0,
        label: "Geniemode India",
        value: "Geniemode India",
        address: {
            address_line_1: "Plot 362 - 363, First Floor, Block B",
            address_line_2: "ASF Center, Udyog Vihar, Phase - 4",
            others: ["Gurugram, Haryana, Pin Code : 122016"],
        },
        compliance: "GST : 06AAICG9521H1ZI",
        beneficiary: "Geniemode Global Pvt Ltd",
        banks: [
            {
                account_no: "50200060106468",
                address: "Sector 56, Huda District Centre, Gurgaon - 122011",
                name: "HDFC BANK",
                others: ["IFSC - HDFC0003871", "SWIFT - HDFCINBB"],
            },
            {
                name: "CITI Bank",
                account_no: "0554637019",
                address: "BABA KHARAG SINGH MARG, New Delhi DL - Delhi IN 110001",
                others: ["IFSC - CITI0000002", "SWIFT - CITIINBX"],
            },
        ],
    },
    {
        id: 1,
        label: "Geniemode US",
        value: "Geniemode US",
        address: {
            address_line_1: "257 Old Churchmans Rd",
            address_line_2: "City of New Castle",
            others: ["Zip Code : 19720"],
            // "257 OLD CHURCHMANS RD, NEW CASTLE, Delaware - 19720, USA",
        },
        compliance: "EIN : 86-3636486",
        beneficiary: "Geniemode Global Inc",
        banks: [
            {
                name: "CITI BANK",
                account_no: "54064931",
                address: "388 Greenwich Street, New York, NY 10013",
                others: ["SWIFT - CITIUS33", "IBAN/ABA - 031100209"],
            },
            // {
            //   name: "SIGNATURE BANK",
            //   account_no: "1504465876",
            //   address: "261, Madison Ave, Lower Level, New York, NY 10016",
            //   others: ["SWIFT - SIGNUS33", "IBAN/ABA - 026013576"],
            // },
        ],
    },
    {
        id: 2,
        label: "Geniemode UK",
        value: "Geniemode UK",
        address: {
            address_line_1: "3, Windrush Avenue",
            address_line_2: "Slough, SL3 8ER",
            others: [
                // "SL3 8ER"
            ],
        },
        compliance: "VAT NO : 6G6X QDME EH4X XQ2",
        beneficiary: "Geniemode UK Ltd",
        banks: [
            {
                name: "ICICI BANK",
                account_no: "76334040",
                address: "ICICI Bank UK PLC, 1 Thomas More Square, London, E1W 1YN",
                others: ["SWIFT - ICICGB2LXXX", "SORT Code - 30-00-81"],
            },
        ],
    },
    {
        id: 3,
        label: "IIPI Global LLP Faridabad",
        value: "IIPI Global LLP Faridabad",
        address: "B-18, DLF PHASE-1, FARIDABAD, Faridabad, Haryana, 121003",
        compliance: "GST - 06AAHFI7524A1ZQ",
        beneficiary: "IIPI Global LLP",
        banks: [
            {
                name: "ICICI Bank Ltd",
                account_no: "721605000262",
                address: "Shop No 7, Anantraj Tech Park, Sector 8, IMT Manesar, Haryana 122051",
                others: ["IFSC - ICIC0007216"],
            },
        ],
    },
    {
        id: 4,
        label: "IIPI Global LLP Noida",
        value: "IIPI Global LLP Noida",
        address: "Plot No 21, Sector 140A, Noida, Gautam Budha Nagar, UP 201301",
        compliance: "GST - 09AAHFI7524A1ZK",
        beneficiary: "IIPI Global LLP",
        banks: [
            {
                name: "ICICI Bank Ltd",
                account_no: "721605000262",
                address: "Shop No 7, Anantraj Tech Park, Sector 8, IMT Manesar, Haryana 122051",
                others: ["IFSC - ICIC0007216"],
            },
        ],
    },
    {
        id: 5,
        label: "ATSPACE International Pvt Ltd",
        value: "ATSPACE International Pvt Ltd",
        address: "B-165, KAMLA NEHRU NAGAR, EXTENSION II, Jodhpur, Rajasthan - 342008",
        compliance: "GST : 08AAWCA4308E1ZL",
        beneficiary: "ATSPACE International Pvt Ltd",
        banks: [
            {
                name: "HDFC Bank Ltd",
                account_no: "50200065600067",
                address: "SCO 39, HUDA District Centre, Sector 56, Gurgaon - 121011",
                others: ["IFSC - HDFC0003871", "SWIFT - HDFCINBB"],
            },
        ],
    },
];

export { _countriesOptions, _countryCodeOptions, _currencyMap, _entity_linked_bank_data };
