import { getUserData } from "../Helper/commonFunctions_v2";

const buildFormData = (obj = {}) => {
    let form_data = new FormData();
    if (!!obj && typeof obj === "object") {
        Object.keys(obj).map((prop) => {
            form_data.append(prop, obj[prop]);
        });
    }
    return form_data;
};

const _mathTOFixedNoRounding = () => {
    Number.prototype.toFixedNoRounding = function (n) {
        if (this && Math.abs(this) < Infinity) {
            const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
            const a = this.toString().match(reg).length > 0 ? this.toString().match(reg)[0] : "0";
            const dot = a.indexOf(".");
            if (dot === -1) {
                // integer, insert decimal dot and pad up zeros
                return a + "." + "0".repeat(n);
            }
            const b = n - (a.length - dot) + 1;
            return b > 0 ? a + "0".repeat(b) : a;
        } else {
            return this;
        }
    };
};

const _isTabletQc = () => {
    let userData = getUserData(),
        // tabQcs = ["chandan.sen@geniemode.com","dheeraj.kumar@iipisourcing.com","pratush.gupta@geniemode.com","satender.singh@geniemode.com","yogesh.kumar@geniemode.com","rajeev.kumar@geniemode.com"],
        tabQcs = ["dheeraj.kumar@iipisourcing.com", "pratush.gupta@geniemode.com", "satender.singh@geniemode.com", "yogesh.kumar@geniemode.com", "rajeev.kumar@geniemode.com", "sonu.kumar@geniemode.com", "yogendra.kumar@geniemode.com", "p.suresh@geniemode.com", "dhayananthan.a@geniemode.com", "udaya.kumar@geniemode.com", "gr.akilesh@geniemode.com", "siva.kumar@geniemode.com", "prem.kumar@geniemode.com", "r.prasath@geniemode.com", "dhirendra.kumar@geniemode.com", "naveen.namdev@geniemode.com", "himanshu.sehrawat@geniemode.com", "parul.tyagi@geniemode.com"],
        // tabQcs = ["dheeraj.kumar@iipisourcing.com", "pratush.gupta@geniemode.com", "satender.singh@geniemode.com", "yogesh.kumar@geniemode.com", "rajeev.kumar@geniemode.com", "sonu.kumar@geniemode.com", "yogendra.kumar@geniemode.com", "p.suresh@geniemode.com", "dhayananthan.a@geniemode.com", "suresh.kumar@geniemode.com", "udaya.kumar@geniemode.com", "gr.akilesh@geniemode.com", "siva.kumar@geniemode.com", "prem.kumar@geniemode.com", "r.prasath@geniemode.com", "praveen.sharma@geniemode.com", "dhirendra.kumar@geniemode.com", "naveen.namdev@geniemode.com", "himanshu.sehrawat@geniemode.com", "parul.tyagi@geniemode.com"],
        // tabQcs = [],
        retVal = false;
    if (userData?.username && tabQcs.includes(userData?.username)) {
        retVal = true;
    }
    return retVal;
};

const _removeURLParam = (key, sourceURL) => {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
};

const resetErrors = (form) => {
    form = [...form];
    form.map((div, i) => {
        // form[i] = {...form[i]};
        // form[i].inputs = [...form[i].inputs];
        form[i].inputs.map((inp, j) => {
            // form[i].inputs[j] = {...form[i].inputs[j]};
            form[i].inputs[j].error = "";
        });
    });
    return form;
};

const errorHandlerForms = (init_form = [], autoSave = false) => {
    let form = [...resetErrors(init_form)],
        hasError = false;
    form.map((div, divIdx) => {
        // form[divIdx] = {...form[divIdx]};
        // form[divIdx].inputs = [...form[divIdx].inputs];
        form[divIdx].inputs.map((inpOrig, inpIdx) => {
            // let inp = {...form[divIdx].inputs[inpIdx]};
            let inp = form[divIdx].inputs[inpIdx];
            if (inp.inputType === "text" || inp.inputType === "textarea") {
                typeof inp.value === "string" && (inp.value = inp.value && inp.value.trim());
                typeof inp.value === "undefined" && (inp.value = "");
                if (!!inp.value && inp.regex && inp.regex !== "") {
                    if (!!inp.required || inp.value !== "") {
                        let patrn = new RegExp(inp.regex);
                        if (!patrn.test(inp.value)) {
                            !autoSave && (inp.error = `Invalid value for field`);
                            hasError = true;
                        }
                    }
                }
                if (inp.minLength) {
                    if (!!inp.required || inp.value !== "") {
                        if (inp.value.length < inp.minLength) {
                            !autoSave && (inp.error = `Field should be minimum ${inp.minLength} characters long`);
                            hasError = true;
                        }
                    }
                }
                if (inp.maxLength && !!inp.value) {
                    if (!!inp.required || inp.value !== "") {
                        if (inp?.value?.length > inp.maxLength) {
                            !autoSave && (inp.error = `Field should be maximum ${inp.maxLength} characters long`);
                            hasError = true;
                        }
                    }
                }
                if (inp.required) {
                    if (!inp.value || inp.value.length === 0) {
                        !autoSave && (inp.error = `Required field`);
                        hasError = true;
                    }
                }
            } else if (inp.inputType === "select" || inp.inputType === "create-select") {
                if (!inp.value && inp.required) {
                    !autoSave && (inp.error = `Select a value`);
                    hasError = true;
                }
                if (inp.isMulti && Array.isArray(inp.value) && inp.required && inp.value.length <= 0) {
                    !autoSave && (inp.error = `Select atleast one value`);
                    hasError = true;
                }
            } else if (inp.inputType === "date-picker" || inp.inputType === "date-picker-native") {
                if (!inp.value && inp.required) {
                    !autoSave && (inp.error = `Select a value`);
                    hasError = true;
                }
            } else if (inp.inputType === "boolean") {
                if (typeof inp.value !== "boolean") {
                    !autoSave && (inp.error = `Input type value is not a boolean`);
                    hasError = true;
                }
            } else if (inp.inputType === "file-upload") {
                if (inp.required && (!inp.title || !inp.file)) {
                    !autoSave && (inp.error = `File Upload is mandatory`);
                    hasError = true;
                }
                if (!!inp.file && !!inp.accept && _isFileTypeAccepted(inp.accept, inp.file.type) === false) {
                    !autoSave && (inp.error = `${inp.file && inp.file.type && inp.file.type.split ? inp.file.type.split("/").join(" ") : "Above "} format is not acceptable`);
                    hasError = true;
                }
            } else if (inp.inputType === "checkbox" || inp.inputType === "radio" || inp.inputType === "multi-option-select") {
                if (inp.required && inp.value.length === 0) {
                    !autoSave && (inp.error = `Select an option`);
                    hasError = true;
                }
            }
            if (inp.error !== "") hasError = true;
            form[divIdx].inputs[inpIdx] = inp;
        });
    });
    return { form, hasError };
};

const Months = () => {
    return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
};

const Days = () => {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
};

const onChange = ({ e, inpID, divIdx, inpIdx, type, form, attr = "value" }) => {
    if (type === "text" && !!e && e.target) {
        if (e.target && e.target.getAttribute("data-pattern")) {
            let patrn = new RegExp(e.target.getAttribute("data-pattern")),
                div = parseInt(e.target.getAttribute("data-div")),
                inp = parseInt(e.target.getAttribute("data-inp")),
                skipInpattern = e.target.getAttribute("data-skipinpattern");
            if (patrn.test(e.target.value) || e.target.value === "" || !!form[div]["inputs"][inp].skipInputPattern) {
                form[div]["inputs"][inp]["value"] = form[div]["inputs"][inp].uppercase ? e.target.value.toUpperCase() : e.target.value;
            }
        } else if (e.target) {
            form[divIdx]["inputs"][inpIdx]["value"] = e.target.value;
        }
    } else if (type === "react-select" || type === "react-select-create") {
        form[divIdx]["inputs"][inpIdx][attr] = e;
        if (attr === "value") form[divIdx]["inputs"][inpIdx]["inputValue"] = "";
    } else if (type === "boolean") {
        form[divIdx]["inputs"][inpIdx]["value"] = !form[divIdx]["inputs"][inpIdx]["value"];
    } else if (type === "date-picker") {
        form[divIdx]["inputs"][inpIdx].value = e;
    } else if (type === "date-picker-native") {
        form[divIdx]["inputs"][inpIdx].value = e?.target?.value;
    } else if (type === "file-upload") {
        const fileList = e.target.files;
        !!fileList && fileList.length > 0 && (form[divIdx]["inputs"][inpIdx] = readFileAndSaveObj(fileList[0], form[divIdx]["inputs"][inpIdx]));
    } else if ((type === "checkbox" || type === "radio") && !!e) {
        let value = form[divIdx].inputs[inpIdx].value;
        if (!form[divIdx].inputs[inpIdx].isMulti) {
            for (let i = 0; i < value.length; i++) {
                if (value[i].value === e.value && !form[divIdx].inputs[inpIdx].disableUncheck) {
                    form[divIdx].inputs[inpIdx].value = [];
                    return form;
                }
            }
            form[divIdx].inputs[inpIdx].value = [e];
        } else {
            for (let i = 0; i < value.length; i++) {
                if (value[i].value === e.value) {
                    form[divIdx].inputs[inpIdx].value.splice(i, 1);
                    return form;
                }
            }
            form[divIdx].inputs[inpIdx].value.push(e);
        }
    } else if (type === "multi-option-select" && !!e) {
        let value = form[divIdx].inputs[inpIdx].value,
            options = form[divIdx].inputs[inpIdx].options,
            action = e.action;
        delete e["action"];
        if (action === "remove") {
            for (let i = 0; i < value.length; i++) {
                if (value[i].value === e.value) {
                    if (!!form[divIdx].inputs[inpIdx]?.searchedTerm && !e.label.toLowerCase().includes(form[divIdx].inputs[inpIdx]?.searchedTerm)) {
                        form[divIdx].inputs[inpIdx].allOptions.push(e);
                    } else {
                        form[divIdx].inputs[inpIdx].options.push(e);
                        form[divIdx].inputs[inpIdx].allOptions.push(e);
                    }
                    form[divIdx].inputs[inpIdx].value.splice(i, 1);
                    return form;
                }
            }
        } else if (action === "add") {
            for (let i = 0; i < options.length; i++) {
                if (options[i].value === e.value) {
                    form[divIdx].inputs[inpIdx].value.push(e);
                    form[divIdx].inputs[inpIdx].options.splice(i, 1);
                    break;
                }
            }
            for (let i = 0; i < form[divIdx].inputs[inpIdx].allOptions.length; i++) {
                if (form[divIdx].inputs[inpIdx].allOptions[i].value === e.value) {
                    form[divIdx].inputs[inpIdx].allOptions.splice(i, 1);
                    break;
                }
            }
            return form;
        }
    }
    return form;
};

const onMultiOptionSelectSearch = ({ e, inpID, divIdx, inpIdx, type, form, attr = "value" }) => {
    form[divIdx].inputs[inpIdx].searchedTerm = e?.target?.value ? e.target.value : "";
    if (!!e?.target?.value) {
        if (form[divIdx].inputs[inpIdx]?.allOptions?.length) {
            let newOptions = [],
                allOptions = form[divIdx].inputs[inpIdx].allOptions;
            for (let i = 0; i < allOptions.length; i++) {
                if (allOptions[i].label.toLowerCase().includes(e.target.value.toLowerCase())) {
                    newOptions.push({ ...allOptions[i] });
                }
            }
            form[divIdx].inputs[inpIdx].options = newOptions;
        }
    } else {
        form[divIdx].inputs[inpIdx].options = form[divIdx].inputs[inpIdx]?.allOptions?.length ? [...form[divIdx].inputs[inpIdx].allOptions] : [];
    }
    return form;
};

const _isFileTypeAccepted = (acceptedTypes, fileType) => {
    if (typeof acceptedTypes === "string" && typeof fileType === "string") {
        acceptedTypes = acceptedTypes.split ? acceptedTypes.split(",") : "";
        for (let i = 0; i < acceptedTypes.length; i++) {
            acceptedTypes[i] = acceptedTypes[i].split("/");
            if (acceptedTypes[i].length !== 2) return null;
        }
        fileType = fileType.split("/");
        if (fileType.length !== 2) return null;
        // matcher
        for (let i = 0; i < acceptedTypes.length; i++) {
            if ((acceptedTypes[i][0] === "*" || acceptedTypes[i][0] === fileType[0]) && (acceptedTypes[i][1] === "*" || acceptedTypes[i][1] === fileType[1])) {
                return true;
            }
        }
        return false;
    }
    return null;
};

const readFileAndSaveObj = (file, formData) => {
    let format = file.type.split("/");
    format = Array.isArray(format) && format.length > 1 ? format[1] : null;
    if (!!format) {
        const name = file.name.replace(/s+/g, "_").replace(/[^A-Za-z0-9_]+/g, ""),
            title = `${name}_${_exportDateISO(new Date())}.${format}`;
        formData.title = title;
        formData.file = new File([file], title, { type: file.type });
        formData.error = "";
        formData.uploading = true;
        return formData;
    }
    return null;
};

const onBlurFunc = ({ e, inpID, divIdx, inpIdx, type, form }) => {
    if (type === "text") {
        form[divIdx].inputs[inpIdx].error = "";
        typeof form[divIdx].inputs[inpIdx].value === "string" && (form[divIdx].inputs[inpIdx].value = form[divIdx].inputs[inpIdx].value && form[divIdx].inputs[inpIdx].value.trim());
        if (form[divIdx].inputs[inpIdx].value !== "") {
            if (form[divIdx].inputs[inpIdx].required) {
                if (!form[divIdx].inputs[inpIdx].value || form[divIdx].inputs[inpIdx].value?.length === 0) {
                    form[divIdx].inputs[inpIdx].error = `Required field`;
                }
            }
            if (form[divIdx].inputs[inpIdx].minLength) {
                if (form[divIdx].inputs[inpIdx].value?.length < form[divIdx].inputs[inpIdx].minLength) {
                    form[divIdx].inputs[inpIdx].error = `Field should be minimum ${form[divIdx].inputs[inpIdx].minLength} characters long`;
                }
            }
            if (form[divIdx].inputs[inpIdx].maxLength) {
                if (form[divIdx].inputs[inpIdx].value?.length > form[divIdx].inputs[inpIdx].maxLength) {
                    form[divIdx].inputs[inpIdx].error = `Field should be maximum ${form[divIdx].inputs[inpIdx].maxLength} characters long`;
                }
            }
            if (form[divIdx].inputs[inpIdx].regex && form[divIdx].inputs[inpIdx].regex !== "") {
                let patrn = new RegExp(form[divIdx].inputs[inpIdx].regex);
                if (!patrn.test(form[divIdx].inputs[inpIdx].value)) {
                    form[divIdx].inputs[inpIdx].error = `Invalid value for field`;
                }
            }
        }
    } else if (type === "react-select" || type === "react-select-create") {
        !form[divIdx].inputs[inpIdx]?.keepError && (form[divIdx].inputs[inpIdx].error = "");
    } else if (type === "date-picker" || type === "date-picker-native") {
        form[divIdx].inputs[inpIdx].error = "";
    }
    return form;
};

const _onInputChangeHandle = ({ value, action, inpID, divIdx, inpIdx, type, form }) => {
    if (action !== "input-change") return form;
    form && (form[divIdx].inputs[inpIdx].inputValue = value);
    form &&
        form.pagination &&
        (form[divIdx].inputs[inpIdx].pagination = {
            ...form[divIdx].inputs[inpIdx].pagination,
            page: 1,
            search: value,
            hasMore: true,
        });
    return form;
};

const _capitalizeString = (str) => {
    if (!!str) {
        str = str.toLowerCase();
        str = str.trim();
        let cap_str = "";
        str.split(" ").map((word) => {
            if (word.length > 0) {
                cap_str += `${word.charAt(0).toUpperCase()}${word.slice(1)} `;
            }
        });
        return cap_str.trim();
    } else {
        return "";
    }
};

const _capitalizeKeyValues = (str) => {
    if (!!str) {
        str = str.toUpperCase();
        str = str.trim();
        let cap_str = "";
        str.split(" ").map((word) => {
            if (word.length > 0) {
                cap_str += `${word} `;
            }
        });
        return cap_str.trim();
    } else {
        return "";
    }
};

const _dateGenerator = (date_string) => {
    try {
        let date_arr = date_string && date_string.split("/");
        if (date_arr.length === 3) {
            let tmp = date_arr[1];
            date_arr[1] = date_arr[0];
            date_arr[0] = tmp;
            date_string = date_arr.join("/");
            return new Date(date_string);
        } else {
            throw new Error(`date string format error - ${date_string}`);
        }
    } catch (error) {
        console.log("_dateGenerator Error ", error);
        return null;
    }
};

const _exportDateISO = (date) => {
    return new Date(new Date(date).getTime() + 19800000).toISOString().substring(0, 10);
};

const _queryParamGenerator = (params) => {
    return Object.keys(params)
        .map((key) => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
        })
        .join("&");
};

const _stepStatusGenerator = (step) => {
    if (step && step.step_name && step.status) {
        if (step && !!step.status) {
            switch (_capitalizeKeyValues(step.status)) {
                case "START":
                    return "NOT_STARTED";
                case "STARTED":
                    return "STARTED";
                case "PENDING APPROVAL":
                    return "PENDING_APPROVAL";
                case "REJECTED":
                    return "REJECTED";
                case "APPROVED":
                    return "APPROVED";
                case "COMPLETE":
                    return "COMPLETED";
                default:
                    return "NOT_STARTED";
            }
        }
    }
    return "NOT_STARTED";
};

const _dateStringFormatted = (date) => {
    let date_string = "";
    if (!!date && date.toDateString) {
        date_string = date.toDateString();
    } else {
        date_string = date && new Date(date) && new Date(date).toDateString();
    }
    if (date_string && typeof date_string === "string" && date_string.split(" ").length === 4) {
        let date_arr = date_string.split(" ");
        return [date_arr[2], date_arr[1], date_arr[3].substring(2)].join(" ");
    } else {
        return "NA";
    }
};

const _orderColorGenerator = (type, score) => {
    // let order_clock_colors = ['#eb0505', '#ec2e2e', '#eb4242', '#e95c5c', '#ec7a7a', '#ee9696'];
    let order_clock_colors = ["rgba(230, 23, 23, 1.0)", "rgba(230, 23, 23, 0.8)", "rgba(230, 23, 23, 0.6)", "rgba(230, 23, 23, 0.45)", "rgba(230, 23, 23, 0.3)", "rgba(230, 23, 23, 0.15)"];
    if (type === "order") {
        return score > 5000 ? order_clock_colors[0] : score >= 1000 ? order_clock_colors[1] : score >= 500 ? order_clock_colors[2] : score >= 100 ? order_clock_colors[3] : score >= 50 ? order_clock_colors[4] : order_clock_colors[5];
    } else {
        return score > 1000 ? order_clock_colors[0] : score >= 100 ? order_clock_colors[2] : order_clock_colors[4];
    }
};

const _numberWithCommas = (num) => {
    if (!isNaN(num)) {
        num = parseFloat(num).toString();
        let predecimal = num.split(".")[0];
        let postdecimal = num.split(".").length > 1 ? num.split(".")[1] : null;
        return !isNaN(num) ? `${predecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${postdecimal ? `.${postdecimal}` : ""}` : "NA";
    }
    return "NA";
};

const _closeAlert = (passedThis = null, state = {}, setState = null) => {
    if (!!passedThis) {
        let alert = {
            display: false,
            message: "",
            greenbutton: "",
            redbutton: "",
            greenfunction: null,
            redfunction: null,
        };
        passedThis && passedThis.setState && passedThis.setState({ alert });
        return alert;
    } else if (!!state && !!setState) {
        let alert = {
            display: false,
            message: "",
            greenbutton: "",
            redbutton: "",
            greenfunction: null,
            redfunction: null,
        };
        setState && setState({ ...state, alert });
        return alert;
    }
    return "Pass this reference";
};

const _reloadAlert = (passedThis = null, message = "", allow_close = true) => {
    if (!!passedThis) {
        let alert = {
            display: true,
            message,
            greenbutton: allow_close ? "Reload" : "",
            redbutton: allow_close ? "Close" : "Reload",
            greenfunction: allow_close ? () => window.location.reload() : null,
            redfunction: allow_close ? () => _closeAlert(passedThis) : () => window.location.reload(),
        };
        passedThis && passedThis.setState && passedThis.setState({ alert });
        return alert;
    }
    return "Pass this reference";
};

const _errorAlert = (passedThis = null, message = "", state = {}, setState = null) => {
    if (!!passedThis) {
        let alert = {
            display: true,
            message,
            greenbutton: "",
            redbutton: "Close",
            greenfunction: null,
            redfunction: () => _closeAlert(passedThis),
        };
        passedThis && passedThis.setState && passedThis.setState({ alert });
        return alert;
    } else if (!!state && !!setState) {
        let alert = {
            display: true,
            message,
            greenbutton: "",
            redbutton: "Close",
            greenfunction: null,
            redfunction: () => _closeAlert(state, setState),
        };
        setState && setState({ ...state, alert });
        return alert;
    }
    return "Pass this reference";
};

const _successAlert = (passedThis = null, message = "") => {
    if (!!passedThis) {
        let alert = {
            display: true,
            message,
            greenbutton: "Close",
            redbutton: "",
            greenfunction: () => _closeAlert(passedThis),
            redfunction: null,
        };
        passedThis && passedThis.setState && passedThis.setState({ alert });
        return alert;
    }
    return "Pass this reference";
};

const _findIdxById = (id, form) => {
    if (!id || !form) return { divIdx: null, inpIdx: null };
    for (let divIdx = 0; divIdx < form.length; divIdx++) {
        for (let inpIdx = 0; inpIdx < form[divIdx].inputs.length; inpIdx++) {
            if (form[divIdx].inputs[inpIdx].id === id) return { divIdx, inpIdx };
        }
    }
    return { divIdx: null, inpIdx: null };
};

const _findDivIdx = (divisionName, form) => {
    if (!divisionName || !form) return null;
    for (let divIdx = 0; divIdx < form.length; divIdx++) {
        if (form[divIdx].division === divisionName) return divIdx;
    }
    return null;
};

const _findIdxByIdArrayIncludes = (id, form) => {
    let retArr = [];
    if (!id || !form) return retArr;
    for (let divIdx = 0; divIdx < form.length; divIdx++) {
        for (let inpIdx = 0; inpIdx < form[divIdx].inputs.length; inpIdx++) {
            if (form[divIdx].inputs[inpIdx]?.id?.includes(id)) retArr.push({ divIdx, inpIdx });
        }
    }
    return retArr;
};

const _findIdxByKeyFilter = (key, filterProp) => {
    if (!key || !filterProp) return null;
    for (let idx = 0; idx < filterProp.builder.length; idx++) {
        if (filterProp.builder[idx].key === key) return idx;
    }
    return null;
};

const _buildCountryCodeOptions = (_countryCodeOptions, isMobile) => {
    let _countryCodeOptionsFormatted = [];
    for (let i = 0; i < _countryCodeOptions.length; i++) {
        let obj = {
            id: _countryCodeOptions[i].code,
            label: isMobile ? `${_countryCodeOptions[i].dial_code} (${_countryCodeOptions[i].code})` : `${_countryCodeOptions[i].dial_code} (${_countryCodeOptions[i].code}) ${_countryCodeOptions[i].name}`,
            value: `${_countryCodeOptions[i].dial_code}$$(${_countryCodeOptions[i].code})$$${_countryCodeOptions[i].name}`,
        };
        _countryCodeOptionsFormatted.push(obj);
    }
    return _countryCodeOptionsFormatted;
};

const _selectPaginationCall = (id = "", passedThis = null, prependFormKey = "") => {
    if (!!id && !!passedThis) {
        let { form } = passedThis.state;
        if (prependFormKey) form = passedThis.state[prependFormKey].form;
        let { divIdx, inpIdx } = _findIdxById(id, form);
        if (!(divIdx === null || inpIdx === null)) {
            if (form[divIdx].inputs[inpIdx]?.pagination?.hasMore) {
                form[divIdx].inputs[inpIdx].css = form[divIdx].inputs[inpIdx].css + " paginated-select";
                if (prependFormKey) {
                    passedThis.setState({ [prependFormKey]: { ...passedThis.state[prependFormKey], form } });
                } else {
                    passedThis.setState({ form });
                }
                let pagination = form[divIdx].inputs[inpIdx].pagination;
                form[divIdx].inputs[inpIdx]?.pagination?.func &&
                    typeof form[divIdx].inputs[inpIdx].pagination.func === "function" &&
                    form[divIdx].inputs[inpIdx].pagination.func({
                        divIdx,
                        inpIdx,
                        page: pagination?.page,
                        size: pagination?.size,
                        searchTerm: pagination?.search,
                        prefill: false,
                    });
            }
        }
    }
};

const _isiPad = () => !!(window.navigator.userAgent.includes("iPad") || (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)));

const _isApp = () => {
    // return !!window.navigator.userAgent.includes("iPad");
    return !!window?.localStorage?.getItem("inApp");
};

const _isMobile = () => !!(typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1);

const _isDesktop = () => !(typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1);

export { buildFormData, errorHandlerForms, _capitalizeString, _capitalizeKeyValues, _dateGenerator, onChange, onBlurFunc, onMultiOptionSelectSearch, _onInputChangeHandle, _exportDateISO, _queryParamGenerator, _stepStatusGenerator, _dateStringFormatted, _orderColorGenerator, _numberWithCommas, _closeAlert, _errorAlert, _successAlert, _findIdxById, _findIdxByIdArrayIncludes, _findDivIdx, _findIdxByKeyFilter, _buildCountryCodeOptions, _selectPaginationCall, _mathTOFixedNoRounding, _isiPad, _isApp, _isMobile, _isDesktop, _removeURLParam, _isTabletQc, resetErrors, _reloadAlert, Months, Days };
